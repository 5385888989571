//const myConsoleError = console.error ? console.error : console.log;
//import { DN_JASIRI, DN_JEST } from "@/dbGlobals";

class Log {
  static get ALL() {
    return 9;
  }
  static get TRACE() {
    return 8;
  }
  static get DEBUG() {
    return 7;
  }
  static get INFO() {
    return 6;
  }
  static get WARNING() {
    return 5;
  }
  static get ERROR() {
    return 4;
  }
  static get TODO() {
    return 3;
  }
  static get BUG() {
    return 2;
  }
  static get FATAL() {
    return 1;
  }
  static get OFF() {
    return 0;
  }

  // NOTE: Change this to return Log.OFF eventually.
  static get defaultLevel() {
    //return DN_JASIRI === DN_JEST ? Log.OFF : Log.DEBUG;
    return Log.DEBUG;
  }

  //set level(level = Log.defaultLevel) {  // This worked before nextjs.
  set level(level) {
    level = level !== null && level !== undefined ? level : Log.defaultLevel;
    this._level = level;
  }

  get level() {
    switch (this._level) {
      case Log.ALL:
        return "ALL";
      case Log.TRACE:
        return "TRACE";
      case Log.DEBUG:
        return "DEBUG";
      case Log.INFO:
        return "INFO";
      case Log.WARNING:
        return "WARNING";
      case Log.ERROR:
        return "ERROR";
      case Log.TODO:
        return "TODO";
      case Log.BUG:
        return "BUG";
      case Log.FATAL:
        return "FATAL";
      default:
        return "Illegal value for Log._level.";
    }
  }

  constructor(level = Log.defaultLevel) {
    this.level = level;
    //this.info("Logging started with level:", this.level);
  }

  all(...msg) {
    if (this._level >= Log.ALL) {
      console.log("  ALL: ", ...msg);
    }
  }
  trace(...msg) {
    if (this._level >= Log.TRACE) {
      console.log("TRACE: ", ...msg);
    }
  }
  debug(...msg) {
    if (this._level >= Log.DEBUG) {
      console.log("DEBUG: ", ...msg);
    }
  }
  info(...msg) {
    if (this._level >= Log.INFO) {
      console.log(" INFO: ", ...msg);
    }
  }
  warning(...msg) {
    if (this._level >= Log.WARNING) {
      console.log("WARNING: ", ...msg);
    }
  }
  error(...msg) {
    if (this._level >= Log.ERROR) {
      console.error("ERROR: ", ...msg);
    }
  }
  todo(...msg) {
    if (this._level >= Log.TODO) {
      console.error("TODO: ", ...msg);
    }
  }
  bug(...msg) {
    if (this._level >= Log.BUG) {
      console.error("***********************************************");
      console.trace();
      console.error("BUG: ", ...msg);
      //this.alert("BUG: ", ...msg); TODO: something like this?
      console.error("***********************************************");
    }
  }
  fatal(...msg) {
    if (this._level >= Log.FATAL) {
      console.error("***********************************************");
      console.trace();
      console.error("FATAL: ", ...msg);
      //alert("FATAL: ", ...msg); TODO: something like this.
      console.error("***********************************************");
    }
  }

  alert(...params) {
    if (typeof alert != "undefined") {
      // We are running in the browser.
      alert(...params);
    } else {
      // We are running on the server, so we can't call alert()
      console.error(...params);
    }
  }
}

if (typeof window != "undefined") {
  if (window.log == null) {
    window.log = new Log();
  }
} else if (typeof global != "undefined") {
  if (global.log == null) {
    global.log = new Log();
  }
}

//import {getLogger} from 'log4js';
//import log4js from 'log4js';
//var log = log4js.getLogger();

/*
var log4js = require('log4js');
//import log4js from 'log4js';
var log = log4js.getLogger();
//console.log(process.ENV);
//log.level = process.ENV == 'undefined' ? 'all' : 'off';
log.level = 'all';
if (typeof window != 'undefined' && window != null && window.log == null) {
  window.log = log;
}
if (typeof global != 'undefined' && global != null && global.log == null) {
  global.log = log;
}
*/

function getLog() {
  //console.log("Enter getLog()");
  if (typeof window != "undefined") {
    if (window.log == null) {
      window.log = new Log();
    }
    return window.log;
  } else if (typeof global != "undefined") {
    if (global.log == null) {
      global.log = new Log();
    }
    return global.log;
  }
}

const log = getLog();
export { log };
//export default Log;
