"use client";

import { createContext, useContext, useEffect, useState } from "react";
import { FETCH_URL } from "@/dbGlobals";
import { useUser as useAuthUserInfo } from "@auth0/nextjs-auth0/client";
import { getAuthId, convertAuthIdToMongoId } from "@/utils/utilsCSAndSS";
import { putUser, getByIds, resetDexieDB } from "./client-side-db2/dexieApis";
import { log } from "@/utils/log";
import { syncDB } from "./client-side-db2/syncDB";

const LOCAL_STORAGE_USER_ID = "userMongoId";
const LOCAL_STORAGE_USER_EMAIL = "userLoginEmail";

const defaultUserMongoInfo = {
  authUser: null,
  userMongo: null, // What default value do we want.
  error: undefined,
  isLoading: true, // Initially loading
  updateUserMongo: undefined,

  // Previous Auth0 login email used, if any.
  // NOTE: this is a value we store in localStorage.
  // We do NOT get this value from the Auth0 module/service.
  userLoginEmail: null,
};

const UserMongoContext = createContext(defaultUserMongoInfo);

function UserMongoProvider(props) {
  const { children } = props;
  const { user: authUser, isLoading: authLoading } = useAuthUserInfo();
  const [loadingStates, setLoadingStates] = useState({
    isLoading: true,
    isInitialized: false,
    userMongo: null,
    userLoginEmail: null,
  });

  useEffect(() => {
    /**
     * RyanW：
     * Handles user authentication state changes and data synchronization
     * 
     * Flow:
     * 1. Logout:
     *    - Reset IndexedDB
     *    - Sync public data only
     *    - Clear local storage
     * 
     * 2. Login:
     *    a) New User or Different User (needReset = true):
     *       - Reset IndexedDB
     *       - Sync all accessible data
     *       - Fetch user data from server
     *       - Save to local storage and IndexedDB
     *       
     *    b) Same User (needReset = false):
     *       - Fetch latest user data from server
     *       - Sync for any updates
     *       - Update local data if needed
     *       
     * This approach ensures:
     * - Data consistency between server and client
     * - Proper handling of user switches
     * - Efficient updates for returning users
     * 
     * Future Scalability Considerations:
     * 1. Data Pagination:
     *    - Implement cursor-based pagination for initial sync
     *    - Load data in chunks based on user interaction
     * 
     * 2. Selective Sync:
     *    - Add priority levels for different types of data
     *    - Implement lazy loading for non-critical data
     *    - Allow users to choose which data to sync
     * 
     * 3. Performance Optimization:
     *    - Add debounce/throttle for frequent updates
     *    - Implement delta updates for changed data only
     *    - Use WebSocket for real-time critical updates
     * 
     * 4. Storage Management:
     *    - Monitor IndexedDB storage limits
     *    - Implement data cleanup for inactive/old data
     *    - Add compression for large datasets
     */
    const fetchData = async () => {
      if (!authUser) {
        try {
          await resetDexieDB();
          await syncDB();
          
          localStorage.removeItem(LOCAL_STORAGE_USER_ID);
          localStorage.removeItem(LOCAL_STORAGE_USER_EMAIL);

          setLoadingStates((prev) => ({
            ...prev,
            isLoading: false,
            isInitialized: true,
            userMongo: null,
          }));
          return;
        } catch (error) {
          log.error("Something went wrong during logout:", error);
          throw error;
        }
      }

      try {
        const authId = getAuthId(authUser);
        const userMongoId = convertAuthIdToMongoId(authId);
        const storedUserId = localStorage.getItem(LOCAL_STORAGE_USER_ID);
        const needReset = !storedUserId || storedUserId !== userMongoId;
        
        if (needReset) {
          await resetDexieDB();
          await syncDB();

          const resUser = await fetch(`${FETCH_URL}/mongo/getData/getUser`);
          const res = await resUser.json();
          const fetchedUserMongo = res?.data?.obj || null;

          if (fetchedUserMongo) {
            localStorage.setItem(LOCAL_STORAGE_USER_ID, userMongoId);
            const userClient = (({ authUser, ...others }) => others)(fetchedUserMongo);
            await putUser({ obj: userClient });

            setLoadingStates((prev) => ({
              ...prev,
              userMongo: fetchedUserMongo,
              isLoading: false,
              isInitialized: true,
              userLoginEmail: authUser.email || null,
            }));

            if (authUser.email) {
              localStorage.setItem(LOCAL_STORAGE_USER_EMAIL, authUser.email);
            }
          }
        } else {
          const resUser = await fetch(`${FETCH_URL}/mongo/getData/getUser`);
          const res = await resUser.json();
          const fetchedUserMongo = res?.data?.obj || null;
          
          if (fetchedUserMongo) {
            const userClient = (({ authUser, ...others }) => others)(fetchedUserMongo);
            await putUser({ obj: userClient });
            await syncDB();
            
            setLoadingStates((prev) => ({
              ...prev,
              userMongo: fetchedUserMongo,
              isLoading: false,
              isInitialized: true,
              userLoginEmail: authUser.email || null,
            }));
          } else {
            await resetDexieDB();
          }
        }
      } catch (error) {
        log.error("Something went wrong during login:", error);
        setLoadingStates((prev) => ({
          ...prev,
          isLoading: false,
          isInitialized: true,
          error: error.message,
        }));
        throw error;
      }
    };

    if (!authLoading) {
      fetchData();
    }
  }, [authUser, authLoading]);

  // Update the properties of the currently signed-in user.
  // This function should be called when the user updates properties such as
  // displayName, description, settings, etc.
  async function updateUserMongo() {
    if (!loadingStates.userMongo?._id) {
      // We were called when no user was signed in, which should never
      // happen, so show an error message so the developer can debug
      // the code.
      log.bug(
        "updateUserMongo was called when no user was signed in.  This should never happen."
      );
      return;
    }

    try {
      const objId = loadingStates.userMongo._id;
      const res = await getByIds({ objId });
      setLoadingStates((prev) => ({
        ...prev,
        userMongo: res?.data?.obj,
      }));
      log.trace("updateUserMongo() updated userMongo successfully.");
    } catch (error) {
      log.error("Error updating userMongo: ", error);
    }
  }

  const userMongoInfo = {
    authUser,
    userMongo: loadingStates.isInitialized ? loadingStates.userMongo : null,
    error: loadingStates.error,
    isLoading: !loadingStates.isInitialized || authLoading,
    updateUserMongo,
    userLoginEmail: loadingStates.isInitialized
      ? loadingStates.userLoginEmail
      : null,
  };

  return (
    <UserMongoContext.Provider value={userMongoInfo}>
      {children}
    </UserMongoContext.Provider>
  );
}

function useUserMongo() {
  return useContext(UserMongoContext);
}

export { UserMongoProvider, useUserMongo };
