import {
  CN_USERS,
  CN_TESTOBJS,
  CN_POSTS,
  CN_LABELS,
  CN_IMAGES,
  CN_WAYS,
  CN_ACTS,
  CN_ITNS,
  CN_SYSTEM_SETTINGS,
  CN_COMMENTS,
  CN_REACTIONS,
  CN_GLOBALS,
  CN_REQUESTS,
  CN_PERMISSIONS,
  CN_GROUPS,
  CN_MESSAGES,
  CN_CATEGORIES,
  CN_RATINGS,
} from "@/dbGlobals";

import Dexie from "dexie";

/**
 * Create a Dexie instance with the DB name "tellus1".
 * NOTE: Increment dexiedb.version(...) whenever you modify the schemaDefinition below.
 */
const dexiedb = new Dexie("tellus1");

/**
 * This schemaDefinition is for version 14.
 * If you change or remove tables in the future, remember to bump the version.
 * Example: dexiedb.version(15).stores({ ... new definitions ... })
 */
const schemaDefinition = {
  [CN_USERS]:
    "&_id, deleted, created, modified, authUser.user_id, displayName, [_id+deleted]",
  [CN_POSTS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_LABELS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_TESTOBJS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_GLOBALS]: "_id, modified, synced, modified_ids",
  [CN_IMAGES]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_WAYS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_ACTS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_ITNS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_SYSTEM_SETTINGS]:
    "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_REACTIONS]: "&_id, deleted, created, modified, type, [_id+deleted]",
  [CN_COMMENTS]: "&_id, deleted, created, modified, content, [_id+deleted]",
  [CN_REQUESTS]: "&_id, deleted, created, modified, content, [_id+deleted]",
  [CN_PERMISSIONS]:
    "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_GROUPS]: "&_id, deleted, created, modified, displayName, [_id+deleted]",
  [CN_MESSAGES]: "&_id, deleted, created, modified, content, [_id+deleted]",
  [CN_CATEGORIES]: "&_id, deleted, created,  displayName, [_id+deleted]",
  [CN_RATINGS]: "&_id, deleted, created, modified, objId, rating, [_id+deleted]",
};

// Set up the DB schema under version 14
dexiedb.version(14).stores(schemaDefinition);

/**
 * (Optional) A small helper to ensure the DB is open before using it.
 * This does not alter the existing flow; it's just available for convenience.
 */
export async function ensureDBOpen() {
  if (!dexiedb.isOpen()) {
    await dexiedb.open();
  }
}


/**
 * Individual table exports for each collection, so they can be imported and used directly.
 * Example: import { usersTable } from "@/client-side-db2/dexie";
 * helps individual modules to import only the tables they need from a shared reference to dexie.js instead of importing the entire dexie.js file.
 * cuts down on the number of imports and makes it easier to see which tables are being used in a module
 */
export const usersTable = dexiedb.table(CN_USERS);
export const testobjsTable = dexiedb.table(CN_TESTOBJS);
export const postsTable = dexiedb.table(CN_POSTS);
export const labelsTable = dexiedb.table(CN_LABELS);
export const imagesTable = dexiedb.table(CN_IMAGES);
export const waysTable = dexiedb.table(CN_WAYS);
export const actsTable = dexiedb.table(CN_ACTS);
export const itnsTable = dexiedb.table(CN_ITNS);
export const systemSettingsTable = dexiedb.table(CN_SYSTEM_SETTINGS);
export const commentsTable = dexiedb.table(CN_COMMENTS);
export const reactionsTable = dexiedb.table(CN_REACTIONS);
export const globalsTable = dexiedb.table(CN_GLOBALS);
export const requestsTable = dexiedb.table(CN_REQUESTS);
export const permissionsTable = dexiedb.table(CN_PERMISSIONS);
export const groupsTable = dexiedb.table(CN_GROUPS);
export const messagesTable = dexiedb.table(CN_MESSAGES);
export const categoriesTable = dexiedb.table(CN_CATEGORIES);
export const ratingsTable = dexiedb.table(CN_RATINGS);

// Export the Dexie instance for direct usage in other modules.
export default dexiedb;
