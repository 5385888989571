import dexiedb from "@/client-side-db2/dexie";
import { getNowIsoStr } from "@/utils/utilsCSAndSS";
import { initDexieDB, deleteDexieDB } from "@/client-side-db2/initDexieDB";
import { getByIds } from "@/client-side-db2/dexieApis";
import {
  ALL_COLLECTION_NAMES,
  CN_GLOBALS,
  DB_SYS_FIELDS,
  ID_GLOBALS_DB,
} from "@/dbGlobals";
import { log } from "@/utils/log";
import { ensureDBOpen } from "@/client-side-db2/dexie";

/**
 * Retrieves the single "globals_db" record from the "globals" Dexie table.
 * If that record doesn't exist (meaning the DB is uninitialized),
 * calls initDexieDB() to create it, then returns the newly created record.
 *
 * @returns {Promise<Object|null>} The globals_db record or null if an error occurs.
 *   Typically, this record contains fields like changedDocs, syncingDocs, dbVersion, etc.
 */
async function getGlobalsDb() {
  try {
    await ensureDBOpen();
    const globalsTable = dexiedb.table(CN_GLOBALS);

    // Transactionally read the "globals_db" doc
    const globals_db = await dexiedb.transaction("r", globalsTable, async () => {
      return await globalsTable.get({ _id: ID_GLOBALS_DB });
    });

    // If it's missing, we initialize the DB (which creates "globals_db") and return it
    if (!globals_db) {
      const initedGlobalsDb = await initDexieDB();
      return initedGlobalsDb;
    }
    return globals_db;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Given a changedDocSummery object, gathers full doc details for "created," "modified," and "deleted" sets.
 *
 * The changedDocSummery has the shape:
 *  {
 *    collectionNameA: {
 *      created: [...docIds],
 *      modified: { docId: [fields], ... },
 *      deleted: [...docIds]
 *    },
 *    collectionNameB: { ... }
 *  }
 *
 * This function:
 *  - Finds actual doc content for any docId in 'created' or 'deleted' or 'modified',
 *  - If a doc is both in 'created' and 'modified', we treat it as newly created doc, not partial update,
 *  - Minimizes the actual fields for 'modified' to the changed fields + system fields,
 *  - Returns an object keyed by collection, with { created, modified, deleted } fully loaded doc content.
 *
 * @param {Object} changedDocSummery - The summary of changed docs keyed by collection name.
 * @returns {Promise<Object>} An object shaped like:
 *   {
 *     collectionNameA: { created: [...], modified: {...}, deleted: {...} },
 *     collectionNameB: { created: [...], modified: {...}, deleted: {...} },
 *     ...
 *   }
 *   Each subfield is the doc data for that category.
 */
async function getChangedDocs(changedDocSummery) {
  log.trace("changedDocSummery:", changedDocSummery);
  const changedDocs = {};

  if (Object.keys(changedDocSummery).length > 0) {
    try {
      await ensureDBOpen();

      for (const key in changedDocSummery) {
        let created = [];
        let modified = {};
        let deleted = {};

        // For "created" doc IDs, fetch full content and filter out any empty placeholder
        if (changedDocSummery[key].created.length > 0) {
          log.trace("created ids:", changedDocSummery[key].created);
          const createdRes = await getByIds({
            objIds: changedDocSummery[key].created,
          });
          // Filter out fallback placeholder objects that have minimal fields
          created = createdRes.data.objs.filter(
            (obj) => Object.keys(obj).length > 1
          );
        }

        // For "modified" doc IDs, fetch the entire doc, then keep only changed fields + system fields.
        if (Object.keys(changedDocSummery[key].modified).length > 0) {
          const modifiedWithAllFieldsRes = await getByIds({
            objIds: Object.keys(changedDocSummery[key].modified),
          });
          const modifiedWithAllFields = modifiedWithAllFieldsRes.data.objs;

          modified = modifiedWithAllFields.reduce((acc, item) => {
            if (item && Object.keys(item).length > 1) {
              let newItem = {};
              // If doc is also newly "created," we keep the full doc
              if (created.includes(item._id)) {
                newItem = item;
              } else {
                // Otherwise, keep only the fields that changed plus system fields
                for (const field of changedDocSummery[key].modified[item._id]) {
                  newItem[field] = item[field];
                }
                for (const sysField of DB_SYS_FIELDS) {
                  newItem[sysField] = item[sysField];
                }
              }
              acc[item._id] = newItem;
            }
            return acc;
          }, {});
        }

        // For "deleted" doc IDs, fetch full content to store minimal info
        if (changedDocSummery[key].deleted.length > 0) {
          log.trace("deleted ids:", changedDocSummery[key].deleted);
          const deletedRes = await getByIds({
            objIds: changedDocSummery[key].deleted,
          });
          log.trace("get doc by deleted ids:", deletedRes);

          deleted = deletedRes.data.objs.reduce((acc, item) => {
            if (item && Object.keys(item).length > 1) {
              if (created.includes(item.Id)) {
                // If the doc was also newly created, store the entire item
                acc[item._id] = item;
              } else {
                // Otherwise store just minimal doc info
                acc[item._id] = {
                  creator: item.creator,
                  modified: item.modified,
                  deleted: item.deleted,
                };
              }
            }
            return acc;
          }, {});
        }

        changedDocs[key] = { created, deleted, modified };
      }
    } catch (error) {
      console.error(error);
    }
  }
  log.trace("got changedDocs:", changedDocs);
  return changedDocs;
}

/**
 * Performs the main sync logic:
 *  1. Reads "globals_db" to see if we have changedDocs or existing "syncingDocs."
 *  2. If none, returns early.
 *  3. If "syncingDocs" is already populated, we anticipate needing another sync pass.
 *     Otherwise, we move "changedDocs" -> "syncingDocs."
 *  4. We fetch the doc content for those "syncingDocs" (via getChangedDocs),
 *     then POST them to /api/mongo/sync.
 *  5. We parse the server's response, update local Dexie docs if the server has newer versions,
 *     handle field permissions, etc.
 *  6. We finalize by updating "synced" and clearing "syncingDocs," returning a boolean
 *     indicating if we still have unsynced changes.
 *
 * @returns {Promise<boolean|undefined>} 
 *   - If the server responded OK, returns true or false for "needSyncAgain."
 *   - If an error occurs, might return undefined or false.
 */
async function syncDBFn() {
  log.trace("start sync function");
  const nowStr = getNowIsoStr();
  let globals_db = await getGlobalsDb();
  let needSyncAgain = false;

  // If the globals record is missing changedDocs or syncingDocs, skip.
  if (!globals_db?.changedDocs || !globals_db?.syncingDocs) {
    return;
  }

  const globalsTable = dexiedb.table(CN_GLOBALS);
  log.trace("globals_db before syncing:", await globalsTable.get(ID_GLOBALS_DB));

  // If we already have "syncingDocs," we anticipate a subsequent pass.
  // Otherwise, we move changedDocs -> syncingDocs now.
  if (Object.keys(globals_db.syncingDocs).length > 0) {
    needSyncAgain = true;
  } else {
    await dexiedb.transaction("rw", globalsTable, async () => {
      const tempGlobalsDb = await globalsTable.get(ID_GLOBALS_DB);
      if (tempGlobalsDb) {
        const copiedTempGlobalsDb = {
          ...tempGlobalsDb,
          syncingDocs: tempGlobalsDb.changedDocs,
          changedDocs: {},
        };
        await globalsTable.put(copiedTempGlobalsDb);
      }
    });
  }

  // Re-read the updated "globals_db," then gather the doc content for "syncingDocs."
  globals_db = await getGlobalsDb();
  const changedDocs = await getChangedDocs(globals_db.syncingDocs);
  log.trace(
    "globals_db copy changedDocs to syncingDocs:",
    await globalsTable.get(ID_GLOBALS_DB)
  );
  log.trace("changed doc send to server:", changedDocs);

  // Attempt to post them to the server
  let toClientRes;
  try {
    toClientRes = await fetch("/api/mongo/sync", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        lastSynced: globals_db.synced,
        submited: nowStr,
        changedDocs,
        dbVersion: globals_db.dbVersion ?? "",
      }),
    });
  } catch (error) {
    log.error(error);
    return; // Return early if we couldn't reach the server
    await ensureDBOpen(); // This line won't execute if we return first
  }

  // Attempt to parse server's JSON
  try {
    const toClientJson = await toClientRes.json();
    log.debug("server returns of the syncDB():", toClientJson);

    const toClient = toClientJson.toClientDocs;
    const syncDetails = toClientJson.syncDetails;
    const updateDbVersion = toClientJson.updateDbVersion;
    const serverDbVersion = toClientJson.serverDbVersion;

    log.trace("docs need to be updated to client:", toClient);

    // If the server says we have a new DB version, we drop local DB and re-init
    try {
      if (updateDbVersion) {
        await deleteDexieDB();
        globals_db = await getGlobalsDb();
        await ensureDBOpen();

        await dexiedb.transaction("rw", globalsTable, async () => {
          await globalsTable.update(ID_GLOBALS_DB, { dbVersion: serverDbVersion });
        });
      }

      // Update local Dexie with any newer docs from the server
      const cNs = Object.keys(toClient);
      const tables = cNs.map((item) => dexiedb.table(item));
      log.trace("cNs:", cNs);

      await dexiedb.transaction("rw", tables, async () => {
        for (let i = 0; i < cNs.length; i++) {
          if (toClient[cNs[i]].length > 0) {
            const ids = toClient[cNs[i]].map((doc) => doc._id);
            log.trace("server return ids:", ids);
            const localDocs = await tables[i].bulkGet(ids);
            log.trace("localDocs:", localDocs);

            // We only local-update docs if the server doc has a 'modified' that is newer
            const docsToAddOrUpdate = toClient[cNs[i]].filter((serverDoc, index) => {
              const localDoc = localDocs[index];
              if (!localDoc?.modified) {
                // If no localDoc or no 'modified,' just accept server doc
                return true;
              }
              const localModified = new Date(localDoc.modified);
              const serverModified = new Date(serverDoc.modified);
              return serverModified > localModified;
            });

            if (docsToAddOrUpdate.length > 0) {
              await tables[i].bulkPut(docsToAddOrUpdate);
            }
          }
        }
      });

      // Mark DB as synced and also update any field permissions from the server
      await dexiedb.transaction("rw", globalsTable, async () => {
        await globalsTable.update(ID_GLOBALS_DB, {
          synced: nowStr,
          syncingDocs: {},
        });

        const fieldPermissionsDb = await globalsTable.get("globals_fieldPermissions");
        const { notViewable, editable, deletable } = fieldPermissionsDb;

        // Gather all doc IDs from the server's toClient
        const serverDocIds = Object.values(toClient).flatMap((items) =>
          items.map((item) => item._id)
        );

        // For each doc, see what fields are forbidToView or permitToModify, or if it's deletable
        for (const objId of serverDocIds) {
          const newNotViewableFields = syncDetails[objId]?.forbidToView ?? [];
          const newEditableFields = syncDetails[objId]?.permitToModify ?? [];
          const newDeletable = syncDetails[objId]?.permitToDelete;

          // Handle notViewable fields
          if (newNotViewableFields.length === 0) {
            delete notViewable[objId];
          } else {
            notViewable[objId] = [];
            notViewable[objId].push(...newNotViewableFields);
          }

          // Handle editable fields
          if (newEditableFields.length === 0) {
            delete editable[objId];
          } else {
            editable[objId] = [];
            editable[objId].push(...newEditableFields);
          }

          // Handle deletable doc IDs
          const index = deletable.indexOf(objId);
          if (!newDeletable) {
            if (index !== -1) {
              deletable.splice(index, 1);
            }
          } else {
            if (index === -1) {
              deletable.push(objId);
            }
          }
        }

        // Update the "globals_fieldPermissions" doc
        await globalsTable.update("globals_fieldPermissions", {
          notViewable,
          editable,
          deletable,
        });
      });

      // Re-check if there's anything in changedDocs (meaning we have new changes)
      const globalsDbAfterSync = await getGlobalsDb();
      log.trace("globals db after sync:", globalsDbAfterSync);
      if (Object.keys(globalsDbAfterSync.changedDocs).length > 0) {
        needSyncAgain = true;
      }

      log.trace("sync task done");
      return needSyncAgain;
    } catch (error) {
      log.error(error);
      // Return false if something in the update process fails
      return false;
    }
  } catch (error) {
    log.error(error);
    // If JSON parse or some other step fails, we do not proceed further
  }
}

/**
 * Removes all docs that have 'deleted' set, from each known collection,
 * and clears the "syncingDocs" in the global record. 
 * Typically called after a full sync has finished, to finalize deletions.
 *
 * Steps:
 *  1. For each collection in ALL_COLLECTION_NAMES, find docs with doc.deleted !== ""
 *  2. Physically remove them from Dexie via bulkDelete
 *  3. In "globals_db," set syncingDocs = {}
 */
async function tasksAfterSync() {
  log.trace("start final task");
  await ensureDBOpen();

  const tables = ALL_COLLECTION_NAMES.map((item) => dexiedb.table(item));
  const globalsTable = dexiedb.table(CN_GLOBALS);

  await dexiedb.transaction("rw", tables, async () => {
    for (let i = 0; i < ALL_COLLECTION_NAMES.length; i++) {
      // Real delete any doc with non-empty "deleted"
      const toDelete = await tables[i]
        .filter((item) => item.deleted !== "")
        .toArray();
      const idsToDelete = toDelete.map((item) => item._id);
      await tables[i].bulkDelete(idsToDelete);
    }
  });

  // Clear syncingDocs in the global record
  await dexiedb.transaction("rw", globalsTable, async () => {
    await globalsTable.update(ID_GLOBALS_DB, { syncingDocs: {} });
  });
  log.trace("final task done");
}

/**
 * Creates a concurrency wrapper that executes a "sync function" (fn) in sequence,
 * and when no further sync is needed, calls a "final task" (finalFn).
 *
 * - We keep an internal queue (taskQueue) so multiple calls don't overlap.
 * - If 'fn' returns a truthy value, we do expect another sync pass. If false, we proceed to finalFn.
 * - If finalFn is running and a new request arrives, we mark it pending for after finalFn completes.
 *
 * @param {Function} fn - Typically your sync function (e.g. syncDBFn).
 * @param {Function} finalFn - A final cleanup function (e.g. tasksAfterSync).
 * @returns {Function} A function that you can call to schedule the sequence logic. 
 *   Internally uses a promise queue to ensure sequential execution.
 */
function ensureSequentialExecution(fn, finalFn) {
  let taskQueue = Promise.resolve();
  let isProcessingFinalTask = false;
  let shouldSyncAgain = false;
  let pendingSync = false;

  return async function () {
    log.trace("in fn sequence", {
      isProcessingFinalTask,
      shouldSyncAgain,
      pendingSync,
    });

    // If final task is in progress, mark pending but don't enqueue new tasks
    if (isProcessingFinalTask) {
      pendingSync = true;
      return;
    }

    // Chain onto the existing queue
    taskQueue = taskQueue.then(async () => {
      const result = await fn();
      log.trace("result from fn:", result);

      // If fn returns truthy, we repeat (shouldSyncAgain)
      shouldSyncAgain = !!result;

      // If no repeat needed and not in final task or pending sync, run finalFn
      if (!shouldSyncAgain && !isProcessingFinalTask && !pendingSync) {
        isProcessingFinalTask = true;
        await finalFn();
        isProcessingFinalTask = false;

        // If a sync request came in while we did final task, handle it next
        if (pendingSync) {
          pendingSync = false;
          await ensureSequentialExecution(fn, finalFn)();
        }
      }
    });
    return taskQueue;
  };
}

/**
 * An exported "syncDB" function that ensures syncDBFn is run sequentially,
 * with tasksAfterSync as the final step if no further sync is needed.
 */
const syncDB = ensureSequentialExecution(syncDBFn, tasksAfterSync);

// For testing in jest, we optionally expose internal parts if needed
if (process.env.NODE_ENV === "test") {
  syncDB.__test__ = {
    getChangedDocs,
    tasksAfterSync,
    ensureSequentialExecution,
    syncDBFn,
  };
}

export { syncDB };
