"use client";

import _ from "lodash";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getBlob,
  deleteObject,
} from "firebase/storage";

import Resizer from "react-image-file-resizer";

import { MAX_IMAGE_SIZE_IN_BYTES } from "@/globalConstants";
import { notify } from "@/utils/utilsCS";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCUsFARiM-Voc_xMRfSRvKeRw_3w8jOFSs",
  authDomain: "trekevo-f6d29.firebaseapp.com",
  projectId: "trekevo-f6d29",
  storageBucket: "trekevo-f6d29.appspot.com",
  messagingSenderId: "817566746526",
  appId: "1:817566746526:web:e6ef0a4b5dab116853b88f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the storage service,
// which is used to create references in your storage bucket
const storage = getStorage();

/*
// Create a storage reference from our storage service
const storageRef = ref(storage);

// You can create a reference to a location lower in the tree,
// say 'images/space.jpg' by passing in this path as a second
// argument when calling ref().
//
// Create a child reference
const imagesRef = ref(storage, "images");
// imagesRef now points to 'images'

// Child references can also take paths delimited by '/'
const spaceRef = ref(storage, "images/space.jpg");
// spaceRef now points to "images/space.jpg"
// imagesRef still points to "images"

// Parent allows us to move to the parent of a reference
const imagesRef2 = spaceRef.parent;
// imagesRef2 now points to 'images'

// Root allows us to move all the way back to the top of our bucket
const rootRef = spaceRef.root;
// rootRef now points to the root

// child(), parent, and root can be chained together multiple times,
// as each returns a reference. The exception is the parent of root,
// which is null.

// Reference's path is: 'images/space.jpg'
// This is analogous to a file path on disk
spaceRef.fullPath;

// Reference's name is the last segment of the full path: 'space.jpg'
// This is analogous to the file name
spaceRef.name;

// Reference's bucket is the name of the storage bucket where files are stored
spaceRef.bucket;

// Reference paths and names can contain any sequence of valid Unicode
// characters, but certain restrictions are imposed.  (See doc.)
// Also, avoid using #, [, ], *, or ?, as these do not work well with
// other tools such as the Firebase Realtime Database or gsutil.
*/

const resizeFile = (file) =>
  new Promise((resolve) => {
    const maxWidth = 1000;
    const maxHeight = 1000;
    const compressFormat = "JPEG"; // JPEG, PNG, WEBP
    const quality = 75;
    const rotation = 0;
    const outputType = "file"; // base64, blob, file
    const minWidth = null;
    const minHeight = null;
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType,
      minWidth,
      minHeight
    );
  });

const blurThumbnail = (file) =>
  new Promise((resolve, reject) => {
    const maxWidth = 500;
    const maxHeight = 500;
    const quality = 100;
    const rotation = 0;
    const outputType = "base64"; // base64, blob, file
    const minWidth = null;
    const minHeight = null;

    let compressFormat = "JPEG"; // Default to JPEG
    const fileType = file.type.toLowerCase();

    if (fileType.includes("png")) {
      compressFormat = "PNG";
    } else if (fileType.includes("webp")) {
      compressFormat = "WEBP";
    }

    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (resizedImageBase64) => {
        // Use Canvas to apply blur effect
        const img = new Image();
        img.src = resizedImageBase64;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = img.width;
          canvas.height = img.height;

          // Draw resized image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Apply blur effect
          ctx.filter = `blur(4px)`;
          ctx.drawImage(canvas, 0, 0);

          // Convert the canvas to a Base64 string
          const base64String = canvas.toDataURL(
            `image/${compressFormat.toLowerCase()}`,
            quality / 100
          );
          resolve(base64String);
        };

        img.onerror = (error) => {
          reject(error);
        };
      },
      outputType,
      minWidth,
      minHeight
    );
  });

async function uploadImageFromFile({ dirName, fileName, file }) {
  log.trace("uploadImageFromFile() file ", file);

  if (file && file.size > MAX_IMAGE_SIZE_IN_BYTES) {
    const type = "info";
    const title = "Image Too Large";
    const msg =
      "Image file is too large.  We will adjust resolution and quality before uploading.";
    notify(type, title, msg);

    file = await resizeFile(file);
    log.trace("compressed file ", file);
  }

  const storageRef = ref(storage, dirName + "/" + fileName);
  const snapshot = await uploadBytes(storageRef, file);
  //log.trace("Image was uploaded.");
  //log.trace("snapshot.metadata.fullPath ", snapshot.metadata.fullPath);
  //log.trace("storageRef.fullPath ", storageRef.fullPath);
  const imageUrl = await getDownloadURL(storageRef);
  //log.trace("imageUrl ", imageUrl);

  // This is gs url that we can use to get the file via the storage API.
  //log.trace("storageRef.toString() ", storageRef.toString());

  //Tumbnail
  const thumbnailBase64 = await blurThumbnail(file);

  const data = {
    fileInfo: {
      imageUrl,
      dirName,
      fileName,
      fullPath: dirName + "/" + fileName,
      thumbnailBase64,
    },
  };

  const res = { data };
  log.info("uploadImageFromFile() finished upload.  Returning fileInfo ", data);
  return res;
}

/**
 *
 * @param {string} fileName Something like "londonBridge.jpeg".
 * @returns JavaScript Blob object.
 */
async function downloadImageAsBlob({ imageUrl, fullPath, dirName, fileName }) {
  if (typeof File === "undefined") {
    log.bug("File not defined.  Must be running on Node.");
    return undefined;
  }
  const fileInfo = await getFileSpecifiers({
    imageUrl,
    fullPath,
    dirName,
    fileName,
  });

  let error;
  let data;
  try {
    const fileInfo = await getFileSpecifiers({
      imageUrl,
      fullPath,
      dirName,
      fileName,
    });
    log.trace("fileInfo ", fileInfo);
    fullPath = fullPath ? fullPath : dirName + "/" + fileName;
    const storageRef = ref(storage, fullPath);
    const blob = await getBlob(storageRef);
    log.trace("downloadImageAsBlob() blob ", blob);
    data = { blob, fileInfo };
  } catch (err) {
    const msg =
      "downloadImageAsBlob() failed to getBlob(), err " + err.toString();
    log.bug(msg);
    log.trace("fileInfo ", fileInfo);
    error = { msg };
  }
  const res = { error, data };
  return res;
}

/**
 * The caller should provide one of the following:
 *
 *    imageUrl
 *    fullPath
 *    dirName AND fileName
 *
 * We can use any of the above as a way to download the
 * image file.
 *
 * @param {string} fullPath The Firestore "fullPath" to the
 * image file.  This is the storageRef.fullPath property.
 * @param {string} dirName The fullPath without the fileName.
 * To generate this, you would need to walk up the tree of
 * storageRef.parent.name values.
 * @param {string} fileName Something like "londonBridge.jpeg".
 * The Firestore "name" of the image file.
 * This is the storeageRef.name property.
 * dirName/fileName is the same as fullPath.
 * @param {string} imageUrl The URL that Firestore provided
 * when we uploaded it.
 * @returns A File, or "File like", object.
 */
async function downloadImageAsFile({ imageUrl, fullPath, dirName, fileName }) {
  if (typeof File === "undefined") {
    log.trace("File not defined.  Must be running on Node.");
    return undefined;
  }

  let error;
  let data;
  try {
    const fileInfo = await getFileSpecifiers({
      imageUrl,
      fullPath,
      dirName,
      fileName,
    });

    if (!fileInfo) {
      log.bug("fileInfo is falsey");
      debugger;
    }
    if (!fileInfo.dirName) {
      debugger;
    }

    fullPath = fileInfo.fullPath;
    fileName = fileInfo.fileName;

    const res = await downloadImageAsBlob({ fullPath });
    const blob = _.get(res, "data.blob", undefined);
    const defaultType = "image/jpeg"; // NOTE: There is no MIME type "image/jpg"
    if (!blob) {
      alert("blob is falsey");
    }
    const type = (blob && blob.type) || defaultType;
    const bits = [blob];
    const options = { type };
    const imageFile = new File(bits, fileName, options);
    log.trace("downloadImageAsFile() imageFile ", imageFile);
    /*
    imageFile.imageUrl = fileInfo.imageUrl;
    imageFile.fullPath = fileInfo.fullPath;
    imageFile.dirName = fileInfo.dirName;
    imageFile.fileName = fileInfo.fileName;
    */
    //imageFile.fileInfo = fileInfo;

    data = { imageFile, fileInfo };
  } catch (err) {
    log.error("downloadImageAsFile() failed, err ", err);
    error = { msg: err.toString() };
  } finally {
  }
  const res = { error, data };
  return res;
}

/**
 * A file/image location in Firestorage can be specified in
 * multiple ways:  imageUrl, fullPath, or dirName/fileName
 * This function takes one of those three ways, and returns
 * an object with all three ways.
 * NOTE: The function is async.
 *
 * @param {string} imageUrl - https://firebasestorage.googleapis.com/v0/b/orobor…=media&token=85ff857d-9f9b-4027-be01-e65697e6f3df
 * @param {string} fullPath - users_auth0|5f5976b63e11cd006ae6597c/Niul2zR_yD7wQ.jpeg
 * @param {string} dirName - users_auth0|5f5976b63e11cd006ae6597c
 * @param {string} fileName - Niul2zR_yD7wQ.jpeg
 * @returns A Promise that resolves to an object that contains the
 * four properties above.
 */
async function getFileSpecifiers({ imageUrl, fullPath, dirName, fileName }) {
  if (imageUrl) {
    const storageRef = ref(storage, imageUrl);
    log.trace(storageRef);
    fullPath = storageRef.fullPath;
    dirName = fullPath.substr(0, fullPath.lastIndexOf("/"));
    fileName = storageRef.name;
  } else if (dirName && fileName) {
    fullPath = dirName + "/" + fileName;
    const storageRef = ref(storage, fullPath);
    imageUrl = await getDownloadURL(storageRef);
  } else if (fullPath) {
    //fileName = fullPath.substring(str.lastIndexOf('/')+1);
    fileName = fullPath.split("\\").pop().split("/").pop();
    dirName = fullPath.substr(0, fullPath.lastIndexOf("/"));
    const storageRef = ref(storage, fullPath);
    imageUrl = await getDownloadURL(storageRef);
  }
  log.trace("imageUrl ", imageUrl);
  log.trace("fullPath ", fullPath);
  log.trace("dirName ", dirName);
  log.trace("fileName ", fileName);

  // TODO: If there is a need, write code to walk storageRef.parent.name
  // values to generate the dirName, if dirName was not passed in.
  return { imageUrl, fullPath, dirName, fileName };
}

async function deleteImageInFirestore({
  imageUrl,
  fullPath,
  dirName,
  fileName,
}) {
  if (typeof File === "undefined") {
    log.trace("File not defined.  Must be running on Node.");
    return undefined;
  }

  let error;
  let data;
  try {
    const fileInfo = await getFileSpecifiers({
      imageUrl,
      fullPath,
      dirName,
      fileName,
    });

    if (!fileInfo.dirName) {
      debugger;
    }

    fullPath = fileInfo.fullPath;
    fileName = fileInfo.fileName;
    log.trace("Creating storageRef with ", fullPath);
    const storageRef = ref(storage, fullPath);
    log.trace("About to delete storageRef ", storageRef);
    await deleteObject(storageRef); // No return value

    const msg = "Deletion of " + fileName + " succeeded.";
    log.info(msg);
    data = { msg };
  } catch (err) {
    log.error("Firestore deleteObj(" + fullPath + ") failed, err ", err);
    error = { msg: err.toString() };
  } finally {
  }
  const res = { error, data };
  return res;
}

function base64ToImage(base64String) {
  const img = new Image();
  img.src = base64String;
  return img;
}

export {
  uploadImageFromFile,
  downloadImageAsFile,
  getFileSpecifiers,
  deleteImageInFirestore,
};
