"use client";

import _ from "lodash";
import { log } from "@/utils/log";
import {
  uploadImageFromFile,
  downloadImageAsFile,
  deleteImageInFirestore,
  getFileSpecifiers,
} from "@/lib/firestore";

const TYPES = {
  NULL: "null",
  OBJECT: "object",
  ARRAY: "array",
  STRING: "string",
  BOOLEAN: "boolean",
  NUMBER: "number",
  DATE: "date",
  DATE_TIME: "date_time",
  is: function (prop, type) {
    return type === Object.prototype.toString.call(prop);
  },
  getType: function (prop) {
    return TYPES[Object.prototype.toString.call(prop)];
  },
};
Object.assign(TYPES, {
  "[object Null]": TYPES.NULL,
  "[object Object]": TYPES.OBJECT,
  "[object Array]": TYPES.ARRAY,
  "[object String]": TYPES.STRING,
  "[object Boolean]": TYPES.BOOLEAN,
  "[object Number]": TYPES.NUMBER,
  "[object Date]": TYPES.DATE,
  "[object DateTime]": TYPES.DATE_TIME,
});

// These are object properties that require special
// logic when modifying them.  I.e. there are
// "side effects" that are required to be executed.
// For example, if we remove an image from a user's
// list of profile images, we need to delete the old
// image on Firestore.  We also might need to decrement
// the user's featuredImageIndex or set it to
// undefined.
const PROPS_REQUIRING_LOGIC = [
  "imageInfos",
  "thumbnailImageInfo",
  "coverImageInfo",
  // TODO: delete these below when gone from code.
  "profileImageInfos",
  "avatarImageInfo",
];

// Remove file and index properties.  We don't want to save those in
// the database.
// TODO: Figure out where this logic should live.  I.e. what part
// of the code "knows" that we don't want to save the file or index
// of an imageInfos object?
function stripOutProps(value) {
  if (_.isArray(value)) {
    const imageInfos = value.map((imageInfo) => {
      const {
        file: throwAwayFile,
        index: throwAwayIndex,
        ...strippedImageInfo
      } = imageInfo;
      return strippedImageInfo;
    });
    return imageInfos;
  } else if (_.isObject(value)) {
    const {
      file: throwAwayFile,
      index: throwAwayIndex,
      ...strippedValue
    } = value;
    return strippedValue;
  } else {
    return value;
  }
}

// TODO: The business logic constants should eventually come from the
// database.  Some sort of collection that contains the descriptions
// of the properties of each type of object in the application.
// For example, how many profile images a user object can contain,
// or the maximum number of characters in a displayName property.

/**
 * Set an object property when that property is a single imageInfo.
 * TODO: Probably want to put imageInfo objects into their own
 * collection and just store the _id in objects that use that image.
 *
 * @param {string} action - "delete", "replace".
 *
 * @returns A res object with either its data or error property defined.
 */
async function setObjPropImage({
  action,
  obj,
  propName,
  imageInfo,
  //onSetObjProp,
  dbInfo,
}) {
  const oldImageInfo = obj[propName];
  let newImageInfo;

  switch (action) {
    case "delete":
      newImageInfo = null; //undefined will not work in patch
      break;
    case "replace":
      newImageInfo = { ...imageInfo };
      newImageInfo = stripOutProps(newImageInfo);
      break;
    default:
      alert("Unhandled action in setObjPropImage(), action ", action);
  }

  // This is the value we are going to pass to patch().
  const props = {
    [propName]: newImageInfo,
  };
  props._id = obj._id;
  const res = await dbInfo.dbFuncs.patch({ obj: props });

  //Update old image's updateAt field
  if (oldImageInfo) {
    const props = {
      _id: oldImageInfo.objId,
      updateAt: new Date(),
    };
    const res = await dbInfo.dbFuncs.patch({ obj: props });
  }

  // NOTE: We might want to send the callback the result of the
  // call to patch().
  /*
  if (onSetObjProp) {
    log.info("Calling onSetObjPropSimple(), which should update globalsCS.");
    //res = onSetObjProp();
    onSetObjProp();
  } else {
    log.info(
      "onSetObjProp not set, so we are NOT updating anyone immediately."
    );
  }
  */

  return res;
}

async function setObjPropImageArray({
  action,
  obj,
  propName,
  imageInfos = [],
  //onSetObjProp,
  dbInfo,
}) {
  // This is the current value of this property in obj.
  let curImageInfos = obj[propName] || [];
  if (curImageInfos.length > 0) {
    curImageInfos = curImageInfos.map((imageInfo) => {
      return {
        objId: imageInfo.objId,
      };
    });
  }

  // This will be the new value we set obj[propName] to
  // when we have added/deleted/replaced values in this
  // copy of the object's current value.
  //
  // Make a shallow copy so we can edit the current array
  // without affecting object's current value.
  let newImageInfos = [...curImageInfos];

  // Keep a list of imageInfos that will need to have their
  // Firestore images deleted.
  const imageInfosToBeDeleted = [];

  const IGNORE = "ignore";
  let newFeaturedImageIndex = IGNORE;

  switch (action) {
    case "append":
      newImageInfos.push(...imageInfos);
      break;

    case "replace":
    case "delete":
      for (const imageInfo of imageInfos) {
        let imageInfoToBeDeleted =
          obj && obj[propName] && obj[propName][imageInfo.index];
        if (imageInfoToBeDeleted) {
          imageInfosToBeDeleted.push(imageInfoToBeDeleted);
        }
        if (action === "replace") {
          // Replace the imageInfo in the array.
          newImageInfos.splice(imageInfo.index, 1, imageInfo);
        } else {
          // delete
          if (obj.featuredImageIndex === imageInfo.index) {
            // The featured image is being deleted, so set the
            // featuredImageIndex to undefined.
            newFeaturedImageIndex = undefined;
          } else if (
            _.isNumber(obj.featuredImageIndex) &&
            obj.featuredImageIndex > imageInfo.index
          ) {
            // The image being deleted is BEFORE the index of of
            // the featured image, so we need to decrement the
            // featured profile image index.
            newFeaturedImageIndex =
              newFeaturedImageIndex === IGNORE
                ? obj.featuredImageIndex - 1
                : newFeaturedImageIndex - 1;
          }
          // Remove the imageInfo from the array.
          newImageInfos.splice(imageInfo.index, 1);

          // // Delete the image from the database.
          // const res2 = await dbInfo.dbFuncs.delVirtual({
          //   objId: imageInfo.objId,
          // });
        }
      }
      log.trace("newImageInfos after replace/delete ", newImageInfos);

      break;
    default:
      alert("In setObjPropImageArray(), unhandled action ", action);
  }
  newImageInfos = stripOutProps(newImageInfos);

  // This is the value we are going to pass to patch().
  const props = {
    [propName]: newImageInfos,
  };

  props._id = obj._id;
  const res = await dbInfo.dbFuncs.patch({ obj: props });

  // See if we have to adjust/remove the featuredImageIndex.
  if (newFeaturedImageIndex !== IGNORE) {
    const props = {
      featuredImageIndex: newFeaturedImageIndex,
    };
    props._id = obj._id;
    const res = await dbInfo.dbFuncs.patch({ obj: props });
  }

  // Update the removed images' updateAt field
  log.debug("imageInfosToBeDeleted ", imageInfosToBeDeleted);
  for (const imageInfo of imageInfosToBeDeleted) {
    const props = {
      _id: imageInfo.objId,
      updateAt: new Date(),
    };
    const res = await dbInfo.dbFuncs.patch({ obj: props });
  }

  return res;
}

/**
 * Set an object's property that has "logic" around setting
 * its value.  For example, a simple property, such as a user's
 * displayName is just a simple string value to set.  But,
 * changing the image used for user's avatar might also involve
 * deleting the previous image from Firestore.
 */
async function setObjPropWithLogic({
  action,
  imageInfos,
  obj,
  propName,
  propValue,
  //onSetObjProp,
  dbInfo,
}) {
  log.trace(`mutators.js setObjPropWithLogic(${action}) obj `, obj);
  let curPropValue = obj[propName];
  let newPropValue;

  switch (propName) {
    case "thumbnailImageInfo":
    case "coverImageInfo":
    case "avatarImageInfo": // Delete avatarImageInfo from code.
      return setObjPropImage({
        action,
        obj,
        propName,
        imageInfo: propValue,
        //onSetObjProp,
        dbInfo,
      });
    case "imageInfos":
    case "profileImageInfos": // Delete profileImageInfos from code.
      return setObjPropImageArray({
        action,
        obj,
        propName,
        imageInfos: propValue,
        //onSetObjProp,
        dbInfo,
      });
    default:
      alert("In setObjPropWithLogic(), unhandled propName ", propName);
  }

  return { error: { msg: "Illegal call to setObjPropWithLogic()" } };
}

/**
 * NOTE: Pass only ONE of the following values:
 * imageInfos, propValue.
 *
 * TODO: Possibly have separate functions for arrays vs. simple props.
 *
 * @param {string} action - "append", "replace", "delete".
 * @param {Object[]} imageInfos - An array of imageInfo objects to be
 * @param {*} propValue - The new value for the property.
 * added/removed from the obj's property.
 * @returns
 */
async function setObjProp({
  action,
  imageInfos,
  obj,
  propName,
  propValue,
  //onSetObjProp,
  dbInfo,
}) {
  log.trace(`mutators.js setObjProp(${action}) imageInfos `, imageInfos);
  log.trace("setObjProp(), obj ", obj);

  // See if this property requires special handling.
  // Properties that require logic are things like images.
  // If you replace an image, we need to delete the old image.
  if (PROPS_REQUIRING_LOGIC.includes(propName)) {
    return setObjPropWithLogic({
      action,
      imageInfos,
      obj,
      propName,
      propValue,
      //onSetObjProp,
      dbInfo,
    });
  } else {
    return setObjPropSimple({
      action,
      imageInfos,
      obj,
      propName,
      propValue,
      //onSetObjProp,
      dbInfo,
    });
  }
}

async function setObjPropSimple({
  action,
  imageInfos,
  obj,
  propName,
  propValue,
  //onSetObjProp,
  dbInfo,
}) {
  let newPropValue;
  if (_.isArray(propValue)) {
    newPropValue = propValue;
  } else if (_.isObject(propValue)) {
    newPropValue = { ...propValue };
    newPropValue = stripOutProps(newPropValue);
  } else {
    newPropValue = propValue;
  }

  switch (action) {
    case "delete":
      break;
    case "replace":
      break;
    default:
      alert("Unhandled action in setObjPropSimple(), action ", action);
  }

  // This is the value we are going to pass to patch().
  const props = {
    [propName]: newPropValue,
  };
  log.trace(
    `mutators.js setObjPropSimple() calling patch() with props `,
    props
  );
  props._id = obj._id;
  const res = await dbInfo.dbFuncs.patch({ obj: props });
  log.trace(
    "mutators.js setObjPropSimple() call to patch() returned res ",
    res
  );

  // NOTE: We might want to send the callback the result of the
  // call to patch().
  /*
  if (onSetObjProp) {
    log.info("Calling onSetObjPropSimple(), which should update globalsCS.");
    //res = onSetObjProp();
    onSetObjProp();
  } else {
    log.info(
      "onSetObjProp not set, so we are NOT updating anyone immediately."
    );
  }
  */
  return res;
}

export { TYPES, setObjProp };
