import { DateTime, Interval } from "luxon";
import { Store as notifier } from "react-notifications-component";
import {
  MODE_EDIT,
  DEFAULT_USER_ICON,
  DEFAULT_ACT_IMAGE,
  DEFAULT_ITN_IMAGE,
  DEFAULT_POST_IMAGE,
  DEFAULT_WAY_IMAGE,
  IMAGE_NOT_SET_IMAGE,
  IMAGE_STATUS,
} from "@/globalConstants";
import {
  CN_USERS,
  CN_GLOBALS,
  CN_TESTOBJS,
  CN_POSTS,
  CN_LABELS,
  CN_IMAGES,
  CN_WAYS,
  CN_ACTS,
  CN_ITNS,
  //CN_USERSETTINGS,
  CN_REQUESTS,
  CN_RATINGS,
} from "@/dbGlobals";
import { getByCollectionName } from "@/client-side-db2/dexieApis";

function utcStrToLocalDateTime(timeStr) {
  const dt = DateTime.fromISO(timeStr);
  return dt;
}

// Converts a "machine" time into something like:
//
// 7 hours ago
// 1 month ago
// 3 months ago
// 16 Aug 2022
//
// Currently, it shows the date if the time is over 1 year ago.
//
// time parameter can be an ISO string from the database or a DateTime object.
function getHumanTimeStr(time) {
  log.trace("getHumanTimeStr() time ", time);
  if (!time) {
    return "";
  }
  if (typeof navigator === "undefined") {
    return "";
  }

  //const dt = new DateTime(time);
  let dt;
  if (time instanceof DateTime) {
    dt = time;
  } else {
    dt = utcStrToLocalDateTime(time);
  }

  const now = DateTime.now();
  log.trace("now.valueOf ", now.valueOf());
  log.trace("dt.valueOf ", dt.valueOf());

  // NOTE: Interval objects can't be negative.
  // "dt" must be less than (i.e. before) "now"
  // If dt is after now, Interval.fromDateTimes() returns NaN.
  // NOTE: Doing a comparison of DateTime objects implicitly
  // calls the valueOf() method to get the values to compare.
  let interval;
  if (dt > now) {
    interval = Interval.fromDateTimes(now, dt);
  } else {
    interval = Interval.fromDateTimes(dt, now);
  }
  log.trace("interval ", interval.length("years"));
  const yearsOld = interval.length("years");
  log.trace("yearsOld ", yearsOld);

  //const dur = Duration.fromObject({ days: -400 });
  //const test = DateTime.now().plus(dur);

  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  let timeStr;
  if (yearsOld < 1) {
    timeStr = dt.toRelative(); // 1 year ago, 4 months ago, 10 minutes ago, in 12 hours
  } else {
    /*
    timeStr = dt.toLocaleString();  // 8/16/2022 (defaults to US format, even in NZ)
    timeStr = dt.toLocaleString();
    timeStr = dt.toRelativeCalendar(); // last year
    timeStr = dt.setLocale(userLocale).toLocaleString(); // 16/08/2022
    timeStr = dt.setLocale(userLocale).toLocaleString(DateTime.DATETIME_SHORT); // 16/08/2022, 12:00 pm
    timeStr = dt.setLocale(userLocale).toLocaleString(DateTime.DATETIME_MED); // 16 Aug 2022, 12:00 pm
    timeStr = dt.setLocale(userLocale).toLocaleString(DateTime.DATE_SHORT); // 16/08/2022
    timeStr = dt.toRelative({ base: test });
    */
    timeStr = dt.setLocale(userLocale).toLocaleString(DateTime.DATE_MED); // 16 Aug 2022
  }

  return timeStr;
}

/**
 * Convert the passed collection name used in the code to an object
 * that contains human readable singular and plural versions for the UI.
 * E.g. CN_USERS, which is "users", becomes "User" and "Users".
 *
 * @param {string} cN - Collection name:  CN_USERS, CN_GLOBALS, CN_TESTOBJS.
 */
function getHumanCn(cN) {
  switch (cN) {
    case CN_USERS:
      return {
        singular: "User",
        plural: "Users",
      };
    case CN_GLOBALS:
      return {
        singular: "Global",
        plural: "Globals",
      };
    case CN_TESTOBJS:
      return {
        singular: "Test Object",
        plural: "Test Objects",
      };
    case CN_POSTS:
      return {
        singular: "Post",
        plural: "Posts",
      };
    case CN_LABELS:
      return {
        singular: "Label",
        plural: "Labels",
      };
    case CN_IMAGES:
      return {
        singular: "Image",
        plural: "Images",
      };
    case CN_WAYS:
      return {
        singular: "Waypoint",
        plural: "Waypoints",
      };
    case CN_ACTS:
      return {
        singular: "Adventure",
        plural: "Adventures",
      };
    case CN_ITNS:
      return {
        singular: "Itinerary",
        plural: "Itineraries",
      };
    case CN_IMAGES:
      return {
        singular: "Image",
        plural: "Images",
      };
    //case CN_USERSETTINGS:
    //  return {
    //    singular: "User Settings",
    //    plural: "User Settings Objects",
    //  };
    case CN_REQUESTS:
      return {
        singular: "Request",
        plural: "Requests",
      };
    case CN_RATINGS:
      return {
        singular: "Rating and Review",
        plural: "Ratings and Reviews",
      };
    default:
      return {
        singular: "Error in getHumanCn(" + cN + ")",
        plural: "Error in getHumanCn(" + cN + ")",
      };
  }
}

/**
 *
 * @param {string} type - "success", "danger", "info", "default", "warning"
 * @param {string} title - The title to display.
 * @param {string} message - The message to display.
 * @returns
 */
function notify(type, title, message) {
  log.trace("Enter notify(), message: ", message);
  const notifyId = notifier.addNotification({
    // NOTE: Hack to prevent duplicate messages appearing
    // when doing development.  Not a problem in production.
    id: message,

    title,
    message,
    //type: "info", // success, danger, info, default, warning
    type,
    //insert: "top",
    container: "top-right",
    //animationIn: ["animate__animated", "animate__fadeIn"],
    //animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      //onScreen: true,
      pauseOnHover: true,
    },
  });
  return notifyId;
}

function notifyRemove(notifyId) {
  log.trace("Enter notifyRemove(" + notifyId + ")");
  if (!notifyId) {
    return;
  }
  log.trace("Calling removeNotification(" + notifyId + ")");
  notifier.removeNotification(notifyId);
}

/**
 * Scroll the specified component into view.
 *
 * Pass EITHER a ref or a selector that we will use to
 * get the ref.
 *
 * @param {ref} ref - A React element ref.
 * @param {string} id - A CSS id query selector.
 * Don't put the # character on it.
 */
function scrollIntoView({ ref, id }) {
  let element;
  if (ref) {
    element = ref && ref.current;
  } else if (id) {
    element = document.querySelector("#" + id);
  } else {
    alert("No element with id(" + id + ") found.  ref ", ref);
  }

  if (element) {
    element.scrollIntoView({
      block: "nearest", // start, center, end, nearest
      //inline: "nearest",  // This is for horizontal scrolling.
      behavior: "smooth",
    });
  }
}

function possiblyScrollIntoView(ref, mode) {
  if (mode === MODE_EDIT) {
    scrollIntoView({ ref: ref });
  }
}

/**
 * Pass EITHER remStr or remNum.
 *
 * @param {string} remStr - A value like "8rem".
 * @param {number} remNum - A value like "8".
 *
 * @return The value in pixels based on the document's
 * current rem setting.  Normally, 1 rem = 16 px
 */
function convertRemToPx({ remStr, remNum = 0 }) {
  let pxNum;
  if (remStr) {
    if (("" + remStr).endsWith("rem")) {
      // Value is specified in rems.  E.g. "8rem"
      remNum = parseInt(remStr);
    } else {
      // TODO: Perhaps handle other units: em, px, ...
      remNum = parseInt(remStr);
    }
  }

  const fontSize = parseInt(
    getComputedStyle(document.documentElement).fontSize
  );
  pxNum = fontSize * remNum;
  return pxNum;
}

function defaultTimeSortFn(a, b) {
  const getTime = (obj) => {
    const time = obj.updatedAt || obj.modified || obj.createdAt;
    return time;
  };

  const timeA = getTime(a);
  const timeB = getTime(b);

  if (!timeA && !timeB) return 0;
  if (!timeA) return 1;
  if (!timeB) return -1;

  return new Date(timeB) - new Date(timeA);
}

/**
 * TODO: This will need to eventually handle pagination.  We cannot return thousands
 * of objects.
 *
 * TODO: Make this code do something sensible when one of the multiple calls to
 * getCollectionByName() we make in the for loop fails.
 *
 * @param {Array} cNArray - An array of collection names.  E.g. [CN_USERS, CN_LABELS,
 * CN_POSTS]
 *
 * @param {Function} filterFn - This function is passed and object and should return
 * true if the object "passes" the filter test and should be included in the returned
 * array of objects.
 *
 * @param {Function} sortFn Sort function. Return a positive, zero or negitive value
 * to denote first item is less then, equal to or greater then the second item.
 * e.g. (a,b)=>a-b for ascending order of numbers;
 * (a,b)=>a.localeCompare(b) for ascending order of strings.
 *
 * @returns An array of objects from the collections specified by cNArray that match
 * filterAndSortState.
 */
async function getByCollectionNames({ cNArray, filterFn, sortFn, pagination }) {
  log.trace("\ngetByCollectionNames(), cNArray ", cNArray);

  let combinedRes = undefined;
  for await (const cN of cNArray) {
    log.trace("for loop cN ", cN);
    const res = await getByCollectionName({ cN, filterFn, sortFn });
    log.trace("res ", res);
    if (res.data) {
      if (!combinedRes) {
        combinedRes = res;
      } else {
        combinedRes.data.objs.push(...res.data.objs);
      }
    }
  }
  // combinedRes.data.objs.sort(sortFn);

  if (combinedRes && combinedRes.data && combinedRes.data.objs) {
    // Sort the combined data
    combinedRes.data.objs.sort(sortFn);

    // Apply pagination
    combinedRes.data.objs = paging({
      fnName: "getByCollectionNames",
      objs: combinedRes.data.objs,
      pagination,
    });
  }

  return combinedRes;
}

function getThumbnailUrl(obj, cn) {
  const defaultImages = {
    users: DEFAULT_USER_ICON,
    acts: DEFAULT_ACT_IMAGE,
    itns: DEFAULT_ITN_IMAGE,
    posts: DEFAULT_POST_IMAGE,
    ways: DEFAULT_WAY_IMAGE,
    labels: IMAGE_NOT_SET_IMAGE,
  };

  if (cn !== CN_USERS) {
    const hasImageInfoButNoValidUrl =
      (obj?.thumbnailImageInfo?.objId &&
        !obj?.thumbnailImageInfo?.fileInfo?.imageUrl) ||
      (obj?.imageInfos?.[0]?.objId &&
        !obj?.imageInfos?.[0]?.fileInfo?.imageUrl);

    if (hasImageInfoButNoValidUrl) {
      return IMAGE_STATUS.NOT_APPROVED;
    }
  }

  const possibleUrls = [
    obj?.thumbnailImageInfo?.fileInfo?.imageUrl,
    obj?.imageInfos?.[0]?.fileInfo?.imageUrl,
    obj?.imageUrl,
    defaultImages[cn],
  ];

  return possibleUrls.find((url) => url) ?? defaultImages.labels;
}

export {
  convertRemToPx,
  possiblyScrollIntoView,
  scrollIntoView,
  getHumanTimeStr,
  getHumanCn,
  notify,
  notifyRemove,
  getByCollectionNames,
  getThumbnailUrl,
  defaultTimeSortFn,
};

function paging({ fnName, objs, pagination }) {
  let result = objs;
  if (pagination) {
    if (!pagination.pageSize || !pagination.pageNumber) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination must have pageSize and pageNumber properties.`
      );
    } else if (!_.isInteger(pagination.pageSize) || pagination.pageSize < 1) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination.pageSize must be a positive integer.`
      );
    } else if (
      !_.isInteger(pagination.pageNumber) ||
      pagination.pageNumber < 1
    ) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination.pageNumber must be a positive integer.`
      );
    } else {
      const offset = (pagination.pageNumber - 1) * pagination.pageSize;
      result = result.slice(offset, offset + pagination.pageSize);
    }
  }
  return result;
}
