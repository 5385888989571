"use client";

import _ from "lodash";
import { useUserMongo } from "@/userMongoContextJin";
import { getMinSec } from "@/utils/utilsCSAndSS";
import { createContext, useContext, useEffect, useState } from "react";
import { ID_GLOBALS_DB, ID_GLOBALS_FIELDPERMISSIONS } from "@/dbGlobals";
import {
  post,
  getByIds,
  getByCollectionName,
  put,
  putUser,
  patch,
  bulkPatch,
  delReal,
  delVirtual,
  // Additional convenience functions that do not have a one-to-one
  // mapping with the DexieDB functions in client-side-db2.
  setObjProp,
  getByCollectionNames,
} from "@/client-side-db1/dexieApisHelpers";
import { log } from "@/utils/log";

const dbInfoContext = createContext({ dbInfo: {} });

/**
 * We provide information about the client-side DexieDB to all
 * the components in the UI.  Most components will want to know
 * when there has been a change to the data they are displaying
 * in order to know that they need to rerender themselves.
 *
 * The client-side DexieDB code keeps information about the
 * latest modified time in a document with id ID_GLOBALS_DB
 * ("globals_db") in the client-side collection CN_GLOBALS
 * ("globals").
 *
 * This DbInfoProvider provides a dbInfo object that is a copy
 * of the one in the client-side db, AND it updates dbInfo when
 * the client-side values in the "globals_db" document change.
 *
 * The useDbInfo() function of this DbInfoProvider returns our
 * dbInfo object that looks like this:
 *
 *   dbInfo = {
 *     globalsCS: {
 *       _id: "globals_db",
 *       modified: "2024-08-03T10:34:38.432+12:00",
 *       synced: "2024-08-03T10:10:50.952+12:00",
 *     },
 *     dbState:{
 *       isReady:true,
 *       isInitializing:false
 *     }
 *     dbFuncs:{
 *       post,
 *       getByIds,
 *       getByCollectionName,
 *       put,
 *       putUser,
 *       patch,
 *       delReal,
 *       delVirtual,
 *       setObjProp,
 *       getByCollectionNames
 *     }
 *   }
 *
 * If a component wants to cause itself to be rerendered on a
 * change to the database, it will put properties such as
 * globalsCS.modified into a useEffect() dependency array.
 */
function DbInfoProvider(props) {
  const { children } = props;

  const userMongoInfo = useUserMongo();

  const [globalsCS, setGlobalsCS] = useState(undefined);
  const [globalsFieldPermissions, setGlobalsFieldPermissions] =
    useState(undefined);

  const [dbState, setDbState] = useState({
    isInitializing: false,
    isReady: false,
  });

  // Run this ONCE when we are first "rendered" to initialize ourself.
  useEffect(() => {
    if (dbState.isReady || dbState.isInitializing) {
      log.trace(
        "DBDexie is ready or initializing, so don't do anything. dbState ",
        dbState
      );
      return;
    } else {
      log.trace("Call getDataAndSetState()");
    }
    async function getDataAndSetState() {
      setDbState({ isInitializing: true, isReady: false });

      const res = await getByIds(setGlobalsCS, { objId: ID_GLOBALS_DB });
      if (res.error) {
        log.bug(
          "In DbInfoProvider useEffect(), getByIds() returned res.error ",
          res.error
        );
      }
      log.trace("DBInfoProvider() useEffect() res ", res);
      const globalsCS = res?.data?.obj;

      const res2 = await getByIds(setGlobalsFieldPermissions, {
        objId: ID_GLOBALS_FIELDPERMISSIONS,
      });
      if (res2.error) {
        log.bug(
          "In DbInfoProvider useEffect(), getByIds() returned res.error for globals_fieldPermissions",
          res2.error
        );
      }
      const fieldPermissions = res2?.data?.obj;
      if (fieldPermissions) {
        setGlobalsFieldPermissions(fieldPermissions);
        dbInfo.globalsFieldPermissions = fieldPermissions;
      }

      log.trace("DBInfoProvider() useEffect() globalsCS ", globalsCS);
      if (globalsCS) {
        setGlobalsCS(globalsCS);
      }
      setDbState({ isInitializing: false, isReady: true });
    }

    log.info(
      "dbInfoContext useEffect calling getDataAndSetState() to initialize itself."
    );
    getDataAndSetState();
  }, [dbState]);

  useEffect(() => {
    log.info("dbState.isReady: ", dbState.isReady);
    if (dbState.isReady) {
    } else {
    }
  }, [dbState.isReady]);

  if (
    _.isEqual(dbInfo.globalsCS, globalsCS) &&
    _.isEqual(dbInfo.dbState, dbState) &&
    _.isEqual(dbInfo.globalsFieldPermissions, globalsFieldPermissions)
  ) {
    // No change.
  } else {
    // We change the global dbInfo object so anyone watching it knows
    // something inside it has changed.
    dbInfo = {
      // This tells you whether this dbInfo object is loading or is
      // ready to be used.
      dbState,

      // globalsCS will be set to a copy of the client-side DexieDB's
      // ID_GLOBALS_DB document.  (The document with _id "globals_db",
      // in the collection "globals", in the client-side DexieDB.)
      globalsCS,

      // The dbInfo.dbFuncs pass setGlobalsCS to utility functions to
      // set the value of globalsCS and notify React components they
      // need to rerender.
      setGlobalsCS,

      setGlobalsFieldPermissions,
      globalsFieldPermissions,

      dbFuncs: {
        post: async (args) => await post(setGlobalsCS, args),
        getByIds: async (args) => await getByIds(setGlobalsCS, args),
        getByCollectionName: async (args) =>
          await getByCollectionName(setGlobalsCS, args),
        put: async (args) => await put(setGlobalsCS, args),
        putUser: async (args) => await putUser(setGlobalsCS, args),
        patch: async (args) => await patch(setGlobalsCS, args),
        bulkPatch: async (args) => await bulkPatch(setGlobalsCS, args),
        delReal: async (args) => await delReal(setGlobalsCS, args),
        delVirtual: async (args) => await delVirtual(setGlobalsCS, args),

        // Extra "convenience" functions beyond the basic functions.
        // These functions are not necessary, but make setting values
        // easier for the UI.
        // NOTE: setObjProp() gets initialized a little bit lower in the code.
        setObjProp: undefined,
        getByCollectionNames: async (args) =>
          await getByCollectionNames(setGlobalsCS, args),
      },
    };

    // NOTE: dbFuncs.setObjProp is set outside the declaration above because
    // we need to pass the dbInfo.dbFuncs.patch function into the
    // dbInfo.dbFuncs.setObjProp function.
    // We might want to change all the dbInfo.dbFuncs to take a dbInfo parameter
    // instead of just the setGlobalsCS parameter.  dbInfo contains information
    // and functions all the React components we write might find useful.
    dbInfo.dbFuncs.setObjProp = async (args) =>
      await setObjProp(dbInfo, userMongoInfo, args);
  }

  log.trace(
    "DbInfoProvider() final dbInfo.globalsCS.modified ",
    getMinSec(dbInfo?.globalsCS && dbInfo?.globalsCS?.modified)
  );
  const component = (
    <dbInfoContext.Provider value={dbInfo}>{children}</dbInfoContext.Provider>
  );
  return component;
}

// Declare this as a global so the object reference dbInfo only changes
// if we make it change.
let dbInfo = {};

function useDbInfo() {
  const dbInfo = useContext(dbInfoContext);
  return dbInfo;
}

export { DbInfoProvider, useDbInfo };
