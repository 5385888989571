import dexiedb, { ensureDBOpen } from "@/client-side-db2/dexie";
import { DEFAULT_CREATED_CS } from "@/globalConstants";
import { log } from "@/utils/log";

/**
 * Initializes the Dexie database with a "globals_db" record and a "globals_fieldPermissions" record
 * inside the "globals" table, if they do not already exist.
 *
 * This record is used to track database-level metadata such as:
 *   - synced: The timestamp or marker of when we last synced.
 *   - modified: Another timestamp/marker for local changes.
 *   - changedDocs & syncingDocs: Structures that hold info about pending doc changes.
 *   - dbVersion: A version string to keep track of server vs. client DB versions.
 *
 * Also creates a "globals_fieldPermissions" doc that defines notViewable, editable, and deletable fields.
 *
 * @returns {Promise<Object>} A Promise that resolves to the 'initData' object
 *   representing the new or just-ensured "globals_db" record. E.g.:
 *     {
 *       _id: "globals_db",
 *       synced: "...",
 *       modified: "...",
 *       changedDocs: {...},
 *       syncingDocs: {...},
 *       dbVersion: ""
 *     }
 */
async function initDexieDB() {
  // Make sure Dexie is open before proceeding.
  await ensureDBOpen();

  // If you previously wanted to fetch external data or seed certain tables, you could do so here.
  // e.g. fetch('/api/mongo/posts'), clear() old data, bulkAdd() new data, etc. That logic is commented out.

  const initData = {
    _id: "globals_db",
    synced: DEFAULT_CREATED_CS,
    modified: DEFAULT_CREATED_CS,
    changedDocs: {},
    syncingDocs: {},
    dbVersion: "",
  };

  const initFieldPermissions = {
    _id: "globals_fieldPermissions",
    notViewable: {},
    editable: {},
    deletable: [],
  };

  // In a transaction on 'globals', insert the above docs. 
  // If "globals_db" or "globals_fieldPermissions" already exist, this might cause a Dexie error for duplicate keys.
  // If you want idempotent behavior, you can first check if they exist before adding them.
  await dexiedb.transaction("rw", dexiedb.globals, async () => {
    // Insert the basic "globals_db" record
    await dexiedb.globals.add(initData);
    // Insert the field-permissions record
    await dexiedb.globals.add(initFieldPermissions);
  });

  log.info("Dexie DB initialized");
  return initData;
}

/**
 * Deletes the entire "tellus1" Dexie database by calling dexiedb.delete().
 * 
 * This removes all stored objects from every table. 
 * If you still intend to use Dexie after calling this, you would need to re-open or re-init.
 *
 * @returns {Promise<void>}
 */
async function deleteDexieDB() {
  await dexiedb.delete();
  log.info("Dexie DB deleted");
}

export { initDexieDB, deleteDexieDB };
