//const DN_JEST = "jestDB";
//const DN_JASIRI = process.env.NEXT_PUBLIC_DN_JASIRI;
const CN_USERS = "users"; //process.env.NEXT_PUBLIC_CN_USERS;
const CN_GROUPS = "groups"; //process.env.NEXT_PUBLIC_CN_GROUPS;
const CN_TESTOBJS = "testobjs"; //process.env.NEXT_PUBLIC_CN_TESTOBJS;
const CN_POSTS = "posts"; //process.env.NEXT_PUBLIC_CN_POSTS;
const CN_LABELS = "labels"; //process.env.NEXT_PUBLIC_CN_LABELS;
const CN_IMAGES = "images"; //process.env.NEXT_PUBLIC_CN_IMAGES;
const CN_PERMISSIONS = "permissions"; //process.env.NEXT_PUBLIC_CN_PERMISSIONS;
const CN_REACTIONS = "reactions"; //process.env.NEXT_PUBLIC_CN_REACTIONS;
const CN_COMMENTS = "comments"; //process.env.NEXT_PUBLIC_CN_COMMENTS;
const CN_REQUESTS = "requests"; //process.env.NEXT_PUBLIC_CN_REQUESTS;
const CN_MESSAGES = "messages"; //process.env.NEXT_PUBLIC_CN_MESSAGES;
const CN_WAYS = "ways";
const CN_ACTS = "acts";
const CN_ITNS = "itns";
const CN_SYSTEM_SETTINGS = "systemsettings";
const ID_SYSTEM_SETTINGS = CN_SYSTEM_SETTINGS + "_settings";
const CN_GLOBALS = "globals";
const CN_CATEGORIES = "categories";
const CN_RATINGS = "ratingsAndReviews";
const ID_GLOBALS_DB = CN_GLOBALS + "_db";
const ID_GLOBALS_USERS = CN_GLOBALS + "_users";
const ID_GLOBALS_FIELDPERMISSIONS = CN_GLOBALS + "_fieldPermissions";

// NOTE: I do not understand whether/how the version string in the
// MongoDB globals_db.dbVersion property (that you must edit by hand)
// and the value of VALUE_INIT_DBVERSION are compared to each other
// or with the value in the client-side dexieDB globals_db.dbVersion.
// If I want the client-side dexieDB to be deleted and rebuilt,
// do I need to change both VALUE_INIT_DBVERSION and the value in
// MongoDB globals_db.dbVersion?  I am assuming this is the case.

// VN: variable name
const VN_DBVERSION = "dbVersion";

//const VALUE_INIT_DBVERSION = "initial";
//const VALUE_INIT_DBVERSION = "1";
// Version "1" to version "2" Changes:
//    Removed CN_USERSETTINGS collection.
const VALUE_INIT_DBVERSION = "2";

// This is all the collection names for collections that are synced.
// As of Aug 4, 2024, that's all but CN_GLOBALS.  CN_IMAGES will be
// added soon.
//
// NOTE: We will eventually have a few subsets of "all" collection names.
// Probably subsets like ALL_ACTIVITY_COLLECTION_NAMES which contains
// "ways", "act", "itn", which are the waypoint, activity, and itinerary
// collections.
const ALL_COLLECTION_NAMES = [
  CN_USERS,
  CN_GROUPS,
  CN_TESTOBJS,
  CN_POSTS,
  CN_LABELS,
  CN_IMAGES,
  CN_PERMISSIONS,
  CN_MESSAGES,
  CN_WAYS,
  CN_ACTS,
  CN_ITNS,
  CN_SYSTEM_SETTINGS,
  CN_REQUESTS,
  CN_REACTIONS,
  CN_COMMENTS,
  CN_CATEGORIES,
  CN_RATINGS,
];

// NOTE: process.env is NOT a standard JavaScript object,
// so you’re not able to use object destructuring.
// Environment variables must be referenced as e.g. process.env.PUBLISHABLE_KEY,
// not const { PUBLISHABLE_KEY } = process.env.
// This also means that console.log(process.env) will show an empty object.
// So, you need to explicitly give the full property name to print it.
//
// http://localhost:3000/api
// https://trk01.vercel.app/api
//
const FETCH_URL = process.env.NEXT_PUBLIC_FETCH_URL;
console.log("FETCH_URL ", FETCH_URL);

// Document manipulate
const DOC_CREATE = "create";
const DOC_MODIFY = "modify";
const DOC_DELETE = "delete";

// Permission constants
const PERMISSIONS_DEFAULT = "permissions_default";
const PERMISSION_ACCESS_PUBLIC = "public";
const PERMISSION_ACCESS_SIGNEDIN = "signedin";
const PERMISSION_ACCESS_PRIVATE = "private";
const PERMISSION_ACCESS_CUSTOM = "custom";

const PERMISSION_SUPERUSERIDS = [];
const PERMISSION_SUPERGROUPIDS = ["groups_root", "groups_admin"];

const PERMISSION_FIELD_CHECK_COLLECTIONS = [CN_MESSAGES, CN_REQUESTS];

const COLLECTIONS_USE_COMMENTS = [CN_POSTS];
const COLLECTIONS_USE_REACTIONS = [CN_POSTS];
const COLLECTIONS_NEED_STATUS_CHECKING = [
  CN_ACTS,
  CN_IMAGES,
  CN_ITNS,
  CN_LABELS,
  CN_POSTS,
  CN_USERS,
  CN_WAYS,
];

const DB_SYS_FIELDS = [
  "_id",
  "created",
  "modified",
  "deleted",
  "creator",
  "toUserId",
  "owner",
  "target_id",
];

const IMAGE_FIELDS = ["imageInfos", "thumbnailImageInfo", "coverImageInfo"];

export {
  FETCH_URL,
  //DN_JASIRI,
  //DN_JEST,
  CN_USERS,
  CN_GROUPS,
  CN_TESTOBJS,
  CN_POSTS,
  CN_LABELS,
  CN_IMAGES,
  CN_PERMISSIONS,
  CN_REACTIONS,
  CN_COMMENTS,
  CN_WAYS,
  CN_ACTS,
  CN_ITNS,
  CN_SYSTEM_SETTINGS,
  ID_SYSTEM_SETTINGS,
  CN_GLOBALS,
  CN_REQUESTS,
  CN_MESSAGES,
  CN_CATEGORIES,
  CN_RATINGS,
  ID_GLOBALS_DB,
  ID_GLOBALS_USERS,
  ALL_COLLECTION_NAMES,
  DOC_CREATE,
  DOC_MODIFY,
  DOC_DELETE,
  PERMISSIONS_DEFAULT,
  PERMISSION_ACCESS_PUBLIC,
  PERMISSION_ACCESS_SIGNEDIN,
  PERMISSION_ACCESS_PRIVATE,
  PERMISSION_ACCESS_CUSTOM,
  PERMISSION_SUPERUSERIDS,
  PERMISSION_SUPERGROUPIDS,
  PERMISSION_FIELD_CHECK_COLLECTIONS,
  DB_SYS_FIELDS,
  ID_GLOBALS_FIELDPERMISSIONS,
  COLLECTIONS_USE_COMMENTS,
  COLLECTIONS_USE_REACTIONS,
  COLLECTIONS_NEED_STATUS_CHECKING,
  VN_DBVERSION,
  VALUE_INIT_DBVERSION,
  IMAGE_FIELDS,
};
