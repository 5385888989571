import _ from "lodash";
import { DateTime } from "luxon";
//import { async } from "@firebase/util";
import { CN_USERS } from "@/dbGlobals";
import {
  PERMISSIONS_DEFAULT,
  PERMISSION_ACCESS_PRIVATE,
  PERMISSION_ACCESS_SIGNEDIN,
  PERMISSION_ACCESS_PUBLIC,
  PERMISSION_ACCESS_CUSTOM,
} from "@/dbGlobals";
import FC from "@/filterConstants";
import {
  PROP_NAMES as PN,
  DEFAULT_USER_ICON,
  MAX_CHARS,
} from "@/globalConstants";
import { log } from "@/utils/log";

const DEFAULT_MAX_LENGTH_IN_CHARS = 80;

function convertAuthIdToMongoId(authId) {
  if (authId && authId.startsWith(CN_USERS)) {
    console.error(
      "In convertAuthIdToMongoId(" +
        authId +
        '), authId already starts with "' +
        CN_USERS +
        '"'
    );
    debugger;
    return authId;
  }
  return CN_USERS + "_" + authId;
}

function convertMongoIdToAuthId(mongoId) {
  return mongoId.substring(mongoId.indexOf("_") + 1);
}

/**
 * Pass in a MongoDB object or just the object's _id.
 * Returns the collection name of the passed in obj or objId.
 * For example:
 *
 *  getCn("users_123122342342") would return "users"
 *
 *  getCn({_id:"users_2423423", displayName:"Steve"})
 *  would return "users"
 *
 * @param {Object} objOrId - An object, or just the object's _id property.
 *
 * @returns The MongoDB/DexieDB collection name that contains
 * the object.  E.g. "users", "acts", "labels", ...
 */
function getCn(objOrId) {
  let objId = undefined;

  if (Array.isArray(objOrId)) {
    objId = objOrId[0]; // Take the first element if it's an array
  } else if (_.isString(objOrId)) {
    objId = objOrId;
  } else if (_.isObject(objOrId)) {
    objId = objOrId._id;
  }

  let cN = undefined;
  if (objId) {
    const parts = objId.split("_");
    cN = parts[0];
  }

  return cN;
}
/**
 * This returns obj.owner or obj.creator if owner is not set to anything.
 *
 * @param {Object} obj - A database object.
 * Returns undefined if obj or obj.creator is undefined.
 * (Should never happen though.)
 */
function getOwner(obj) {
  return obj?.owner ? obj.owner : obj?.creator;
}

// -0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz
const digit =
  "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~";
function toB64(x) {
  return x
    .toString(2)
    .split(/(?=(?:.{6})+(?!.))/g)
    .map((v) => digit[parseInt(v, 2)])
    .join("");
}

//function fromB64(x) {
//  return x.split("").reduce((s, v) => s * 64 + digit.indexOf(v), 0);
//}

/**
 *
 * @returns A random 5 character string.  e.g. z7Yji, 8dChe, kQwVI
 */
function randomStr() {
  const random = new Array(5).join().replace(/(.|$)/g, () => {
    return ((Math.random() * 36) | 0)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
  });
  return random;
}

/**
 * Creates an id that is of the form:
 *
 *    <collection name>_<time converted to base 64>_<random 5 char string>
 *
 * For example, if cN was "objs", it could return this:
 *
 *    objs_16006613793253_i7SvY
 *
 * @param {string} cN The collection name that will be used as
 * part of the id.
 *
 * @returns A unique id, that looks like this if the passed
 * in cN was "objs": objs_16006613793253_i7SvY
 */
function createNewId(cN = "") {
  const random = randomStr();

  // Number of milliseconds elapsed since January 1,
  // 1970 00:00:00 UTC.
  const nowTime = Date.now();

  //const version = "nowTime";
  //const version = "afterStartTime";
  const version = "compactB64";
  let id;
  if (version === "nowTime") {
    // time string is nowTime (milliseconds).
    // e.g. objs_1698097173778_8z9O8
    const prefix = cN ? cN + "_" : "";
    id = prefix + nowTime + "_" + random;
  } else if (version === "afterStartTime") {
    // time string is milliseconds after a "startTime".
    // e.g. objs_25566028015_q6dmB
    const startTime = new Date("2023-01-01").getTime();
    const time = nowTime - startTime;
    const prefix = cN ? cN + "_" : "";
    id = prefix + time + "_" + random;
  } else if (version === "compactB64") {
    // time string is the nowTime converted to B64.
    // Not human readable or sortable, but is compact.
    // e.g. objs_NiTQ2Xg_i7SvY
    const time = nowTime;
    const timeB64 = toB64(time);
    const prefix = cN ? cN + "_" : "";
    id = prefix + timeB64 + "_" + random;
  }

  return id;
}

/**
 * TODO: We need to be able to handle .png and other image types.
 * Should we upload them as they are, or convert everything to .jpeg?
 *
 * @param {string} extension File extension.  Should match image type.
 */
function createImageFilename({ extension = "jpeg" }) {
  const id = createNewId("") + extension;
  return id;
}

/**
 * Pass in an Auth0 user object.  Returns the authId we will use to
 * create our MongoDB user object's id.
 *
 * authUser.user_id or authUser.sub might look like this:
 *
 *    "auth0|62c78995ee4ce084167e29ca"
 *
 * and the userMongo._id we create from it would look like this:
 *
 *    "users_auth0|62c78995ee4ce084167e29ca"
 *
 * This function is needed because the Auth0 system puts the authId
 * in a property called "sub" when the user logs in, but after
 * the user has logged in the id can be found in the authUser
 * object in a property named something like:
 *
 *    "https://jasiri.com/user_id"
 *
 * Literally, the property would be:
 *
 *    authUser["https://jasiri.com/user_id"]:"auth0|62c78995ee4ce084167e29ca"
 *
 * The exact name of the property is set in the process.env variable
 *
 *    process.env.AUTH0_PROP_NAME_PREFIX
 *
 * @param {Object} authUser - An Auth0 user object from the Auth0
 * module.  I.e. this is NOT a MongoDB user object that we created.
 * @returns The Auth0 id string of the Auth0 user.
 * E.g. "auth0|62c78995ee4ce084167e29ca"
 */
function getAuthId(authUser) {
  const authId = authUser && (authUser.user_id || authUser.sub);
  return authId;
}

function capitalizeFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

async function possiblyDelayForTesting(delay) {
  if (delay || process.env.NEXT_PUBLIC_DELAY_MS) {
    delay = delay ? delay : process.env.NEXT_PUBLIC_DELAY_MS;
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
}

function isAsyncFunction(func) {
  return func && func.constructor && func.constructor.name === "AsyncFunction";
}

function isArray(variable) {
  //return Array.isArray(variable);
  //return variable instanceof Array;
  return variable && variable.constructor === Array;
}

function isObject(variable) {
  //return Object.prototype.toString.call(variable) === '[object Object]';
  return (
    typeof variable === "object" && variable !== null && !isArray(variable)
  );
}

function isString(variable) {
  //return typeof variable === "string" || variable instanceof String;
  return _.isString(variable);
}

// isoStr example:  "2023-10-16T19:07:02.826Z"
function createDateTimeFromIsoStr(isoStr) {
  return DateTime.fromISO(isoStr);
}

function getNowIsoStr() {
  return DateTime.now().toISO({ includeOffset: true });
}

/**
 * This is just for debugging.  It returns just the minutes and seconds
 * from the passed in isoStr.
 *
 *    isoStr:  2024-08-30T10:23:21.193+12:00
 *    returns: 23:21
 *
 * @param {string} isoStr - An ISO time string.
 */
function getMinSec(isoStr) {
  return isoStr ? isoStr.slice(14, -10) : "No Value";
}

function getWhereThisCodeIsRunning() {
  let whereThisCodeIsRunning;
  try {
    if (window && window.location) {
      whereThisCodeIsRunning = "browser";
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      ) {
      }
    } else {
      whereThisCodeIsRunning = "node";
    }
  } catch (e) {
    // Running on server.  Could be local or Vercel.
    log.info("exception thrown in getWhereThisCodeIsRunning()");
    whereThisCodeIsRunning = "node";
  }

  return whereThisCodeIsRunning;
}

/**
 * Possibly append a space " " and the classToAppend to className.
 * Note that className is NOT appended if it is falsey.
 * The new className is the return value.
 *
 * @param {string} className - The original className string to which
 * classToAppend MIGHT be appened.
 * @param {string} classToAppend - The className that will be appened
 * if it is truthy.
 */
function appendClass(className, classToAppend) {
  if (classToAppend) {
    return className + " " + classToAppend;
  } else {
    return className;
  }
}

/**
 * Return one of the following:  thumbnailImageInfo, featured profile
 * imageInfo, or first imageInfo from the array of profile images.
 *
 * @param {Object} obj - The object from which we will return
 * one image that can be used.
 */
function getOneImageInfo(obj) {
  const thumbnailImageUrl = _.get(
    obj,
    "thumbnailImageInfo.fileInfo.imageUrl",
    undefined
  );
  if (thumbnailImageUrl) {
    return obj.thumbnailImageInfo;
  }
  const featuredImageIndex = obj.featuredImageIndex;
  if (featuredImageIndex !== undefined && featuredImageIndex !== null) {
    const featuredImage = _.get(
      obj,
      "imageInfos[" + featuredImageIndex + "].fileInfo.imageUrl",
      undefined
    );
    if (featuredImage) {
      return obj.imageInfos[featuredImageIndex];
    }
  }
  const firstProfileImage = _.get(
    obj,
    "imageInfos[0].fileInfo.imageUrl",
    undefined
  );
  if (firstProfileImage) {
    return obj.imageInfos[0];
  }
  return undefined;
}

// Always returns a new string.
// If the passed in value is not a string, returns "".
function truncateAtWord(
  str = "",
  maxLengthInChars = DEFAULT_MAX_LENGTH_IN_CHARS
) {
  if (!str || !_.isString(str)) {
    return "";
  }
  if (str.length > maxLengthInChars) {
    str = _.truncate(str, {
      length: maxLengthInChars,
      separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
    });
  }
  return str;
}

/**
 * Truncate the passed in string if it is longer than
 * maxLengthInChars.  Truncate at a word boundary.
 * I.e. don't cut off part of a word.
 *
 * If the passed in value is not a string, returns "".
 *
 * @param {string} str
 * @param {number} maxLengthInChars
 * @returns A new string.
 */
function truncate(str = "", maxLengthInChars = DEFAULT_MAX_LENGTH_IN_CHARS) {
  // let strs = str ? str?.split(/\r?\n/) : [];

  // const strsTruncated = strs.map((str) => {
  //   return truncateAtWord(str);
  // });

  // const strTruncated = strsTruncated.join("\n");
  // return strTruncated;

  return truncateAtWord(str, maxLengthInChars);
}

function getLocation(obj) {
  const location = obj && obj.locationInfo && obj.locationInfo.location;
  if (
    !location ||
    location.lat === undefined ||
    location.lat === null ||
    location.lng === undefined ||
    location.lng === null
  ) {
    // location is not a legal lat/lng value.
    return undefined;
  } else {
    return location;
  }
}

function getAverageLocation(objArray) {
  if (!Array.isArray(objArray)) {
    console.error("Invalid input: objArray is not an array");
    return undefined;
  }

  const validLocations = objArray
    .map(getLocation)
    .filter((location) => location !== undefined);

  if (validLocations.length === 0) {
    return undefined;
  }

  const sumLocation = validLocations.reduce(
    (acc, location) => {
      acc.lat += location.lat;
      acc.lng += location.lng;
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  const averageLocation = {
    lat: sumLocation.lat / validLocations.length,
    lng: sumLocation.lng / validLocations.length,
  };
  return averageLocation;
}

function hasProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

async function getAvatarUrlByUserId(userId, dbInfo) {
  try {
    const currentUser = await dbInfo.dbFuncs.getByIds({
      objId: userId,
    });

    const user = _.get(currentUser, "data.obj", null);
    const avatarUrl = [
      user?.thumbnailImageInfo?.fileInfo?.imageUrl,
      user?.imageInfos?.[0]?.fileInfo?.imageUrl,
      user?.imageUrl,
      DEFAULT_USER_ICON,
    ];
    const avatarUrl2 = avatarUrl.find((url) => url);
    const userName = _.get(currentUser, "data.obj.displayName", "");

    return { avatarUrl: avatarUrl2, userName };
  } catch (error) {
    log.error("exception thrown in getAvatarUrlByUserId()", error);
    return "";
  }
}

function nestComments(comments) {
  const commentMap = {};
  comments.forEach((comment) => {
    commentMap[comment._id] = { ...comment, replies: [] };
  });

  const nestedComments = [];
  comments.forEach((comment) => {
    if (comment.parent_id) {
      if (commentMap[comment.parent_id]) {
        commentMap[comment.parent_id].replies.push(commentMap[comment._id]);
      } else {
        nestedComments.push({
          _id: comment.parent_id,
          creatorInfo: { userName: "Anonymous" },
          content: "This comment has been deleted.",
          isDeleted: true,
          replies: [commentMap[comment._id]],
        });
      }
    } else {
      nestedComments.push(commentMap[comment._id]);
    }
  });

  return nestedComments;
}

/**
 * Set obj.permissions if no value is already specified.
 *
 * @param {Object} obj - A single object.
 * @param {Object} objs - An array of objects.
 * @param {string} [permissionAccess=PERMISSION_ACCESS_SIGNEDIN] -
 * One of the following values: PERMISSION_ACCESS_PUBLIC,
 * PERMISSION_ACCESS_SIGNEDIN (default), PERMISSION_ACCESS_PRIVATE,
 * PERMISSION_ACCESS_CUSTOM
 */
function possiblySetPermissions({
  cN,
  obj,
  objs,
  permissionAccess = PERMISSION_ACCESS_SIGNEDIN,
}) {
  log.trace("possiblySetPermissions() obj ", obj);
  log.trace("possiblySetPermissions() objs ", objs);
  if (obj && !obj.permissions) {
    cN = cN ? cN : getCn(obj);
    if (!cN) {
      debugger;
    }
    const permission = PERMISSIONS_DEFAULT + "_" + cN + "_" + permissionAccess;
    obj.permission = permission;
  }
  if (Array.isArray(objs)) {
    objs.forEach((obj) => possiblySetPermissions({ cN, obj }));
  }
}

const copyObjContent = (obj, currentUserId) => {
  let newObject = _.cloneDeep(obj);
  newObject.displayName = obj.displayName
    ? `Copy of ${obj.displayName.substring(0, 22)}`
    : "";

  if (hasProperty(obj, PN.OWNER)) {
    newObject.owner = undefined;
  }
  newObject.creator = currentUserId;

  delete newObject.status;
  delete newObject.created;
  delete newObject.deleted;
  delete newObject.updatedAt;
  delete newObject._id;
  delete newObject.modified;

  for (let key in newObject) {
    if (key.toLowerCase().includes("imageinfo")) {
      if (Array.isArray(newObject[key])) {
        newObject[key] = newObject[key].map((item) => ({ objId: item.objId }));
      } else if (
        typeof newObject[key] === "object" &&
        newObject[key] !== null
      ) {
        newObject[key] = { objId: newObject[key].objId };
      }
    }
  }

  if (
    hasProperty(newObject, PN.PERMISSION) &&
    newObject.permission.includes("public")
  ) {
    newObject.permission = newObject.permission.replace("public", "private");
  }

  return newObject;
};

function filterObjs(objs, filterInfo) {
  const searchTerm = filterInfo.search?.trim().toLowerCase() || "";
  const filterLabels = filterInfo.labels || [];

  return objs.filter((item) => {
    // Filter by status
    if (
      !hasProperty(item, FC.FILTER_STATUS) ||
      item.status.state !== "Approved"
    ) {
      return false;
    }

    // Filter by labels
    if (hasProperty(filterInfo, FC.FILTER_LABEL) && filterLabels.length > 0) {
      const itemLabelIds = item.labelIds || [];
      const hasMatchingLabel = filterLabels.some((labelId) =>
        itemLabelIds.includes(labelId)
      );
      if (!hasMatchingLabel) {
        return false;
      }
    }

    // Filter by search term
    if (hasProperty(filterInfo, FC.FILTER_SEARCH) && searchTerm !== "") {
      const hasDisplayName = item.displayName
        ?.toLowerCase()
        .includes(searchTerm);
      const hasDescription = item.description
        ?.toLowerCase()
        .includes(searchTerm);
      const hasMessage = item.message?.toLowerCase().includes(searchTerm);

      // If none of these fields match the search term, exclude the item
      if (!hasDisplayName && !hasDescription && !hasMessage) {
        return false;
      }
    }

    return true;
  });
}

/**
 * Create a URL with a list of searchParams.  For example,
 * "/shell/objsPage?objId=users_auth0|66ff429a0d79c4147e77af5a&propName=friends&title=Your%20Friends"
 *
 * @param {String} beginning - The start of the URL without the "?".
 * For example, "/shell/objsPage"
 * @param {Object} searchParams - An object where each property's
 * name is the name of the searchParam, and the value of the
 * property is the value used.  For example,
 * {
 *    [SP.OBJ_ID]:"users_auth0|66ff429a0d79c4147e77af5a",
 *    [SP.FRIENDS]:"Your Friends"
 * }
 */
function createUrl({ beginning, searchParams = {} }) {
  let url = beginning;
  if (url.at(-1) === "?") {
    // The caller "mistakenly" already added the '?'
    // character to the beginning of the URL, so remove it.
    url = url.slice(0, -1);
  }
  const entries = Object.entries(searchParams);
  if (entries.length < 1) {
    return url;
  }
  url += "?";
  for (const [key, value] of entries) {
    if (url.at(-1) !== "?") {
      url += "&";
    }
    url += key + "=" + value;
  }
  return url;
}

function checkEditPermission(obj, dbInfo, userMongoInfo, isSuperUser) {
  const currentUserId = userMongoInfo?.userMongo?._id;
  const ownerId = getOwner(obj);
  const isOwner = currentUserId && ownerId && currentUserId === ownerId;

  if (isOwner || isSuperUser) {
    return true;
  }

  const fields = Object.keys(obj);
  return fields.some((field) =>
    dbInfo?.globalsFieldPermissions?.editable?.[obj._id]?.includes(field)
  );
}

function checkDeletePermission(obj, dbInfo, userMongoInfo, isSuperUser) {
  const currentUserId = userMongoInfo?.userMongo?._id;
  const ownerId = getOwner(obj);
  const isOwner = currentUserId && ownerId && currentUserId === ownerId;

  if (isOwner || isSuperUser) {
    return true;
  }
  return dbInfo?.globalsFieldPermissions?.deletable?.includes(obj._id);
}

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

//Check the length of fields' content
const checkDocFieldsLength = (doc) => {
  if (!doc) return { isValid: true };

  // skip the check for images
  if (hasProperty(doc, PN.IMAGE_URL)) {
    return { isValid: true };
  }

  const results = {
    isValid: true,
    fieldsToTrim: [],
  };

  for (const [field, value] of Object.entries(doc)) {
    if (!value) continue;

    let maxLength;
    switch (field) {
      case "displayName":
        maxLength = MAX_CHARS.NAME;
        break;
      case "email":
        if (value.length > MAX_CHARS.EMAIL) {
          results.isValid = false;
          continue;
        }
        const emailPrefix = value.split("@")[0];
        if (emailPrefix.length > MAX_CHARS.EMAIL_PREFIX) {
          results.isValid = false;
          continue;
        }
        continue;
      default:
        maxLength = MAX_CHARS[field.toUpperCase()];
    }

    if (maxLength && value.length > maxLength) {
      results.fieldsToTrim.push({ field, maxLength });
      results.isValid = false;
    }
  }

  return results;
};

const trimDocFields = (doc, fieldsToTrim) => {
  if (!doc || !fieldsToTrim.length) return doc;

  const trimmedDoc = { ...doc };
  for (const { field, maxLength } of fieldsToTrim) {
    trimmedDoc[field] = doc[field].substring(0, maxLength);
  }
  return trimmedDoc;
};

export {
  convertAuthIdToMongoId,
  convertMongoIdToAuthId,
  createNewId,
  createImageFilename,
  getAuthId,
  capitalizeFirstLetter,
  isAsyncFunction,
  possiblyDelayForTesting,
  isString,
  isArray,
  isObject,
  getCn,
  getOwner,
  createDateTimeFromIsoStr,
  getNowIsoStr,
  getMinSec,
  getWhereThisCodeIsRunning,
  randomStr,
  appendClass,
  getOneImageInfo,
  truncate,
  getLocation,
  getAverageLocation,
  hasProperty,
  getAvatarUrlByUserId,
  nestComments,
  possiblySetPermissions,
  copyObjContent,
  filterObjs,
  createUrl,
  checkEditPermission,
  checkDeletePermission,
  calculateDistance,
  checkDocFieldsLength,
  trimDocFields,
};
