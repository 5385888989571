


import _ from "lodash";
import dexiedb, { ensureDBOpen } from "@/client-side-db2/dexie";
import { initDexieDB } from "@/client-side-db2/initDexieDB";
import { createNewId, getCn, getNowIsoStr } from "@/utils/utilsCSAndSS";
import { DEFAULT_CREATED_CS } from "@/globalConstants";
import {
  CN_GLOBALS,
  ID_GLOBALS_DB,
  CN_USERS,
  ALL_COLLECTION_NAMES,
  DOC_CREATE,
  DOC_MODIFY,
  DOC_DELETE,
  DB_SYS_FIELDS,
} from "@/dbGlobals";
import { syncDB } from "@/client-side-db2/syncDB";
import { log } from "@/utils/log";

/**
 * The original function was commented out, but it used to record modified object IDs
 * in the globals DB for quick retrieval. Currently not in use.
 */
// async function addObjIdToGlobals(objIds) {
//   if (_.isString(objIds)) {
//     objIds = [objIds];
//   }
//   const globalsTable = dexiedb.table(CN_GLOBALS);
//   await dexiedb.transaction("rw", globalsTable, async () => {
//     const globalsDb = await globalsTable.get(ID_GLOBALS_DB);
//     //get modified_ids field
//     const modifiedIds = globalsDb.modified_ids;
//     //change modified_ids list to set
//     const modifiedIdsWithSet = Object.fromEntries(
//       Object.entries(modifiedIds).map(([key, value]) => [key, new Set(value)])
//     );

//     objIds.forEach((item) => {
//       const cN = getCn(item);

//       if (!modifiedIdsWithSet[cN]) {
//         modifiedIdsWithSet[cN] = new Set();
//       }
//       modifiedIdsWithSet[cN].add(item);
//     });

//     //change back to list
//     const updatedModifiedIds = Object.fromEntries(
//       Object.entries(modifiedIdsWithSet).map(([key, value]) => [
//         key,
//         Array.from(value),
//       ])
//     );
//     await globalsTable.update(ID_GLOBALS_DB, {
//       modified_ids: updatedModifiedIds,
//     });
//   });


// }

/**
 * Adds create/modify/delete metadata into the "changedDocs" field of our "globals_db" record.
 * 
 * - "changedDocs" is structured by collection name, e.g.:
 *    {
 *      testobjs: {
 *        created: [...],
 *        modified: { docId: [fields...], ... },
 *        deleted: [...]
 *      },
 *      ...
 *    }
 * - This data is used to track local changes that need syncing to the server.
 *
 * @param {Object|Object[]} docInfo - A single doc action or an array of doc actions.
 *   Each action has the form: 
 *     { id: "collection_docId", action: "create"/"modify"/"delete", fields?: string[] }
 *   "fields" is used only for DOC_MODIFY to list changed fields.
 * @returns {Promise<void>}
 */
async function addChangedDocInfoToGlobal(docInfo) {
  if (!_.isArray(docInfo)) {
    docInfo = [docInfo];
  }
  log.trace("docInfo:", docInfo);

  const globalsTable = dexiedb.table(CN_GLOBALS);
  log.trace("global_db before add changed doc:", await globalsTable.get(ID_GLOBALS_DB));

  await dexiedb.transaction("rw", globalsTable, async () => {
    const globalsDb = await globalsTable.get(ID_GLOBALS_DB);
    const changedDocs = globalsDb.changedDocs;

    for (const docAct of docInfo) {
      const cN = getCn(docAct.id);
      if (!changedDocs[cN]) {
        changedDocs[cN] = { created: [], modified: {}, deleted: [] };
      }

      // Action cases: create, modify, delete
      if (docAct.action === DOC_CREATE) {
        if (!changedDocs[cN].created.includes(docAct.id)) {
          changedDocs[cN].created.push(docAct.id);
        }
      } else if (docAct.action === DOC_MODIFY) {
        // If doc is marked newly "created," we keep it as "created" only
        if (!changedDocs[cN].created.includes(docAct.id)) {
          // Then add new fields if already in "modified," or set them if not
          if (!changedDocs[cN].modified[docAct.id]) {
            changedDocs[cN].modified[docAct.id] = docAct.fields;
          } else {
            for (const field of docAct.fields) {
              if (!changedDocs[cN].modified[docAct.id].includes(field)) {
                changedDocs[cN].modified[docAct.id].push(field);
              }
            }
          }
        }
      } else if (docAct.action === DOC_DELETE) {
        // If doc was newly created, remove it from "created." 
        // Otherwise remove it from "modified," and push to "deleted."
        const idx = changedDocs[cN].created.indexOf(docAct.id);
        if (idx !== -1) {
          changedDocs[cN].created.splice(idx, 1);
        } else {
          if (changedDocs[cN].modified.hasOwnProperty(docAct.id)) {
            delete changedDocs[cN].modified[docAct.id];
          }
          if (!changedDocs[cN].deleted.includes(docAct.id)) {
            changedDocs[cN].deleted.push(docAct.id);
          }
        }
      }
    }

    await globalsTable.update(ID_GLOBALS_DB, { changedDocs });
    log.trace("updated global_db:", await globalsTable.get(ID_GLOBALS_DB));
  });
}

/**
 * Sorts an array of objects using an optional user-defined comparison function.
 * 
 * @param {string} fnName - The name of the calling function (for logging).
 * @param {Object[]} objs - The array of objects to sort.
 * @param {Function} sortFn - The comparison function to use (a,b) => number.
 * @returns {Object[]} The sorted array, or the original array if sortFn is invalid.
 */
function sortObjs({ fnName, objs, sortFn }) {
  let result = objs;
  if (sortFn) {
    if (_.isFunction(sortFn)) {
      result = result.sort(sortFn);
    } else {
      log.error(
        `Calling ${fnName}: Invalid param sortFn, the results are not sorted. The sortFn must be a function with two parameters.`
      );
    }
  }
  return result;
}

/**
 * Applies pagination to an array, returning only the slice for the requested page.
 * 
 * @param {string} fnName - For logging context.
 * @param {Object[]} objs - The array of objects to paginate.
 * @param {Object} pagination - Must have pageNumber & pageSize. Both must be positive integers.
 * @returns {Object[]} The paged slice of objs, or the original array if invalid pagination.
 */
function paging({ fnName, objs, pagination }) {
  let result = objs;
  if (pagination) {
    if (!pagination.pageSize || !pagination.pageNumber) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination must have pageSize and pageNumber properties.`
      );
    } else if (!_.isInteger(pagination.pageSize) || pagination.pageSize < 1) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination.pageSize must be a positive integer.`
      );
    } else if (!_.isInteger(pagination.pageNumber) || pagination.pageNumber < 1) {
      log.error(
        `Calling ${fnName}: Invalid param pagination, the results are not paged. The pagination.pageNumber must be a positive integer.`
      );
    } else {
      const offset = (pagination.pageNumber - 1) * pagination.pageSize;
      result = result.slice(offset, offset + pagination.pageSize);
    }
  }
  return result;
}

/**
 * Inserts one or more new documents into Dexie, marking them in changedDocs as "create."
 * Also updates the 'modified' field in "globals_db" and optionally calls syncDB.
 *
 * @param {string} cN - The Dexie collection name (e.g. "posts").
 * @param {Object|Object[]} objs - A single doc or array of docs to create.
 * @param {boolean} [awaitSyncDB=true] - If true, we await the sync; if false, we fire sync but don't await.
 * @returns {Object} On success, returns { data: { obj, objs }}. If error, returns { error: {...} }.
 */
async function post({ cN, objs, awaitSyncDB = true }) {
  if (!_.isString(cN)) {
    console.error("Invalid param: cN must be a string!");
    return {
      error: {
        msg: "Calling getByCollectionName() error: the param cN must be a string!",
      },
    };
  }

  if (!ALL_COLLECTION_NAMES.includes(cN)) {
    log.error(`Invalied collection name! cN(${cN})`);
    return {
      error: {
        msg: `Invalied collection name! Collection name must be defined in Dexie DB. cN(${cN})`,
      },
    };
  }

  const nowStr = getNowIsoStr();
  log.trace("post time:", nowStr);

  try {
    await ensureDBOpen();

    const table = dexiedb.table(cN);
    const globalsTable = dexiedb.table("globals");

    // Wrap single doc in array
    if (!Array.isArray(objs)) {
      objs = [objs];
    }

    // Assign Dexie system fields
    objs = objs.map((item) => {
      item._id = createNewId(cN);
      item.created = nowStr;
      item.modified = nowStr;
      item.deleted = "";
      return item;
    });

    // Prepare doc info for changedDocs
    const toBeAddedToGlobal = objs.map((item) => ({
      id: item._id,
      action: DOC_CREATE,
    }));

    // Perform transaction: add docs, update 'globals_db' modified, record changedDocs
    await dexiedb.transaction("rw", [table, globalsTable], async () => {
      await table.bulkAdd(objs);

      const existingGlobalsDb = await globalsTable.get("globals_db");
      if (!existingGlobalsDb) {
        initDexieDB();
      }
      await globalsTable.update("globals_db", { modified: nowStr });
      await addChangedDocInfoToGlobal(toBeAddedToGlobal);
    });

    log.trace("post call syncDB");
    if (awaitSyncDB) {
      await syncDB();
    } else {
      syncDB();
    }

    // Return the first doc plus the entire doc array
    return { data: { obj: objs[0], objs } };
  } catch (error) {
    log.error(error);
    return {
      error: { msg: `Calling post() of dexieApis error: ${error}` },
    };
  }
}

/**
 * Retrieves docs by ID(s) from Dexie. Each ID has a collection prefix (like "posts_123").
 * If multiple IDs belong to multiple collections, we handle that in one transaction.
 * 
 * @param {string} [objId] - Single doc ID.
 * @param {string[]} [objIds] - Multiple doc IDs.
 * @param {Function} [sortFn] - Optional sort comparison function.
 * @param {Object} [pagination] - { pageSize, pageNumber }, optional.
 * @returns {Object} On success: { data: { obj, objs }}. If error, returns { error: {...} }.
 */
async function getByIds({ objId, objIds, sortFn, pagination }) {
  // Must choose exactly one: objId or objIds
  if (
    (objId !== undefined && objIds !== undefined) ||
    (objId === undefined && objIds === undefined)
  ) {
    console.error("You must provide either objId or objIds");
    return {
      error: {
        msg: "The function getByIds() must be provided either objId or objIds",
      },
    };
  }

  if (_.isArray(objIds) && objIds.length === 0) {
    return {
      error: { msg: "Empty objIds." },
    };
  }

  let ids = objId || objIds;
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  // Group IDs by collection name
  const cNandId = ids.map((item) => ({ cn: getCn(item), id: item }));
  const groupedByCn = cNandId.reduce((acc, item) => {
    if (!acc[item.cn]) {
      acc[item.cn] = [];
    }
    acc[item.cn].push(item.id);
    return acc;
  }, {});

  try {
    await ensureDBOpen();

    const cNs = Object.keys(groupedByCn);
    const tables = cNs.map((cn) => dexiedb.table(cn));

    // In a single transaction, retrieve docs from each collection in bulk
    const unsortedResult = await dexiedb.transaction("r", tables, async () => {
      let result = [];
      for (let i = 0; i < cNs.length; i++) {
        const docs = await tables[i].bulkGet(groupedByCn[cNs[i]]);
        result = [
          ...result,
          ...docs.map((item, index) => item ?? { _id: groupedByCn[cNs[i]][index] }),
        ];
      }
      return result;
    });

    // If no sortFn, reorder them to match the original 'ids' order
    let sortedResult;
    if (!sortFn) {
      const resultMap = new Map(unsortedResult.map((obj) => [obj?._id, obj]));
      sortedResult = ids.map((id) => resultMap.get(id));
    }

    // If a sortFn is provided, sort them
    sortedResult = sortObjs({
      fnName: "getByIds()",
      objs: sortedResult || unsortedResult,
      sortFn,
    });

    // Then apply paging
    sortedResult = paging({
      fnName: "getByIds()",
      objs: sortedResult,
      pagination,
    });

    return { data: { obj: sortedResult[0], objs: sortedResult } };
  } catch (error) {
    return {
      error: { msg: `Calling getById() of dexieApis error: ${error}` },
    };
  }
}

/**
 * Retrieves documents from a single Dexie collection, with optional filtering, 
 * sorting, pagination, and the choice to include 'deleted' docs or skip them.
 *
 * @param {string} cN - The collection name (e.g. "posts").
 * @param {boolean} [includeDeleted=false] - If true, we skip the "deleted" check.
 * @param {Function} [filterFn=()=>true] - A function that returns true/false if a doc passes the filter.
 * @param {Function} [sortFn] - Optional sort function (a,b)=>number.
 * @param {Object} [pagination] - { pageNumber, pageSize }, optional.
 * @returns {Object} If success: { data: {obj, objs}}, else { error: {...}}
 */
async function getByCollectionName({
  cN,
  includeDeleted = false,
  filterFn = () => true,
  sortFn,
  pagination,
}) {
  if (!_.isString(cN)) {
    console.error("Invalid param: cN must be a string!");
    return {
      error: {
        msg: "Calling getByCollectionName() error: the param cN must be a string!",
      },
    };
  }

  if (!ALL_COLLECTION_NAMES.includes(cN)) {
    log.error(`Invalied collection name! cN(${cN})`);
    return {
      error: {
        msg: `Invalied collection name! cN(${cN})`,
      },
    };
  }

  try {
    await ensureDBOpen();

    let result;
    if (includeDeleted) {
      result = await dexiedb.table(cN).filter(filterFn).toArray();
    } else {
      result = await dexiedb
        .table(cN)
        .where("deleted")
        .equals("")
        .filter(filterFn)
        .toArray();
    }

    result = sortObjs({
      fnName: "getByCollectionName()",
      objs: result,
      sortFn,
    });

    result = paging({
      fnName: "getByCollectionName()",
      objs: result,
      pagination,
    });

    return { data: { obj: result[0], objs: result } };
  } catch (error) {
    log.trace(error);
    return {
      error: {
        msg: `Calling getByCollectionName() of dexieApis error: ${error}`,
      },
    };
  }
}

/**
 * Inserts or updates a single doc in Dexie. If cN is omitted, we parse obj._id to find it.
 * - If obj._id belongs to "users", we also set obj.creator = obj._id
 * - Adds missing 'created' / 'deleted' fields, updates 'modified' to now,
 * - And logs the doc as "modified" in changedDocs.
 * 
 * @param {Object} obj - The doc to put. Must have an _id or a cN must be provided.
 * @param {string} [cN] - The collection name if the doc's _id is not enough or is absent.
 * @returns {Object|undefined} If success, returns { data: { obj, objs:[obj] }}. If missing cN and _id, returns undefined. 
 */
async function put({ obj, cN }) {
  if (!obj._id && !cN) {
    console.error("You must provide collection name of the object.");
    return;
  }
  if (obj._id && cN && getCn(obj._id) !== cN) {
    console.error("obj._id not fit the collection.");
    return;
  }
  if (!cN) {
    cN = getCn(obj._id);
  }

  // If it's a user doc, we link creator to itself
  if (cN === CN_USERS) {
    obj.creator = obj._id;
  }

  try {
    await ensureDBOpen();
    const table = dexiedb.table(cN);
    const globalsTable = dexiedb.table("globals");

    await dexiedb.transaction("rw", [table, globalsTable], async () => {
      const nowStr = getNowIsoStr();
      obj.modified = nowStr;
      if (!obj.created) {
        obj.created = nowStr;
      }
      if (!obj.deleted) {
        obj.deleted = "";
      }

      await table.put(obj);
      await globalsTable.update("globals_db", { modified: nowStr });

      // Mark doc as "modify" in changedDocs, listing all fields except DB_SYS_FIELDS
      await addChangedDocInfoToGlobal({
        id: obj._id,
        action: DOC_MODIFY,
        fields: Object.keys(obj).reduce((acc, key) => {
          if (!DB_SYS_FIELDS.includes(key)) {
            acc.push(key);
          }
          return acc;
        }, []),
      });
    });

    return { data: { obj, objs: [obj] } };
  } catch (error) {
    log.error(error);
    return { error: { msg: `Calling put() of dexieApis error: ${error}` } };
  }
}

/**
 * Specifically updates (or inserts) a doc in the "users" collection, requiring that doc._id parse to "users".
 * We do not automatically update 'modified' or 'deleted' fields here.
 *
 * @param {Object} obj - The doc to store. Must parse to "users" in the ID.
 * @returns {Object} If success, { data: { obj }}. If invalid, { error: {...} }.
 */
async function putUser({ obj }) {
  const cN = getCn(obj._id);
  if (cN !== CN_USERS) {
    console.error("Invalid obj_id, this object may not be an user object.");
    return {
      error: {
        msg: "Calling putUser() error: Invalid obj_id, this object may not be an user object.",
      },
    };
  }

  // If user doc, set creator to itself
  obj.creator = obj._id;

  try {
    await ensureDBOpen();
    const table = dexiedb.table(cN);

    await dexiedb.transaction("rw", table, async () => {
      // We do not set created/modified here intentionally
      await table.put(obj);
    });

    return { data: { obj } };
  } catch (error) {
    log.error(error);
    return { error: { msg: `Calling putUser() of dexieApis error: ${error}` } };
  }
}

/**
 * Partially updates one doc by merging fields with existing data.
 * - We require obj._id. 
 * - We update 'modified' to now, record changed fields in changedDocs, and optionally call syncDB.
 *
 * @param {Object} obj - The doc changes to apply; must have _id.
 * @param {boolean} [awaitSyncDB=true] - If true, we await the sync. Else we fire it asynchronously.
 * @returns {Object|undefined} On success, { data: { obj, objs:[obj] }}; if missing _id, returns undefined.
 */
async function patch({ obj, awaitSyncDB = true }) {
  if (!obj._id) {
    console.error("You must provide _id of the object.");
    return;
  }
  const cN = getCn(obj._id);

  try {
    await ensureDBOpen();
    const table = dexiedb.table(cN);
    const globalsTable = dexiedb.table("globals");

    await dexiedb.transaction("rw", [table, globalsTable], async () => {
      const nowStr = getNowIsoStr();
      log.trace("patch time:", nowStr);

      obj.modified = nowStr;
      // Dexie .update merges these changes
      await table.update(obj._id, obj);
      await globalsTable.update("globals_db", { modified: nowStr });

      // Mark doc as "modify" with changed fields
      await addChangedDocInfoToGlobal({
        id: obj._id,
        action: DOC_MODIFY,
        fields: Object.keys(obj).reduce((acc, key) => {
          if (!DB_SYS_FIELDS.includes(key)) {
            acc.push(key);
          }
          return acc;
        }, []),
      });
    });

    log.trace("patch call syncDB");
    if (awaitSyncDB) {
      await syncDB();
    } else {
      syncDB();
    }
    return { data: { obj, objs: [obj] } };
  } catch (error) {
    log.error(error);
    return { error: { msg: `Calling patch() of dexieApis error: ${error}` } };
  }
}

/**
 * Applies partial updates to multiple docs in a single transaction.
 * - Each doc is updated with 'modified' = now, then recorded in changedDocs as "modify."
 * - We then optionally syncDB.
 *
 * @param {Object[]} objs - The docs to update. Each must have _id. 
 * @param {boolean} [awaitSyncDB=true] - If true, wait for sync to finish.
 * @returns {Object|undefined} On success, { data: { obj, objs }}, else an error object or undefined.
 */
async function bulkPatch({ objs, awaitSyncDB = true }) {
  if (!_.isArray(objs)) {
    log.error("bulkPatch: objs must be an object Array");
    return;
  }

  const objsGroupByCn = objs.reduce((acc, item) => {
    const cn = getCn(item);
    if (!acc[cn]) {
      acc[cn] = [];
    }
    acc[cn].push(item);
    return acc;
  }, {});

  log.debug("objsGroupByCn:", objsGroupByCn);

  try {
    await ensureDBOpen();
    const nowStr = getNowIsoStr();

    // For each collection, Dexie transaction updates docs with 'modified' = now
    const cNs = Object.keys(objsGroupByCn);
    log.debug("cNs:", cNs);
    const tables = cNs.map((cn) => dexiedb.table(cn));

    await dexiedb.transaction("rw", tables, async () => {
      for (let i = 0; i < cNs.length; i++) {
        const docs = objsGroupByCn[cNs[i]];
        for (const item of docs) {
          await tables[i].update(item._id, {
            ...item,
            modified: nowStr,
          });
        }
      }
    });

    const globalsTable = dexiedb.table("globals");
    dexiedb.transaction("rw", globalsTable, async () => {
      await globalsTable.update("globals_db", { modified: nowStr });

      // Mark each doc as "modify"
      await addChangedDocInfoToGlobal(
        objs.map((obj) => ({
          id: obj._id,
          action: DOC_MODIFY,
          fields: Object.keys(obj).reduce((acc, key) => {
            if (!DB_SYS_FIELDS.includes(key)) {
              acc.push(key);
            }
            return acc;
          }, []),
        }))
      );
    });

    if (awaitSyncDB) {
      await syncDB();
    } else {
      syncDB();
    }

    return {
      data: {
        obj: { _id: objs[0]._id },
        objs: objs.map((item) => ({ _id: item._id })),
      },
    };
  } catch (error) {
    log.error(error);
    return {
      error: { msg: `Calling bulkPatch() of dexieApis error: ${error}` },
    };
  }
}

/**
 * Permanently removes docs from the local Dexie DB. 
 * - We expect either objId or objIds, not both. 
 * - The code infers each doc's collection from its ID prefix.
 *
 * @param {string} [objId] - A single doc ID like "posts_123".
 * @param {string[]} [objIds] - Multiple doc IDs. 
 * @returns {Object|undefined} On success, returns { data: { obj, objs }}. If invalid params, returns undefined.
 */
async function delReal({ objId, objIds }) {
  // Must provide exactly one of objId or objIds
  if (
    (objId !== undefined && objIds !== undefined) ||
    (objId === undefined && objIds === undefined)
  ) {
    console.error("You must provide either obj_id or obj_ids");
    return;
  }

  let ids = objId || objIds;
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  // Group IDs by collection
  const groupedByCn = ids.reduce((acc, item) => {
    const cn = getCn(item);
    if (!acc[cn]) {
      acc[cn] = [];
    }
    acc[cn].push(item);
    return acc;
  }, {});

  try {
    await ensureDBOpen();

    const cNs = Object.keys(groupedByCn);
    const tables = cNs.map((cn) => dexiedb.table(cn));

    // Bulk delete each group of IDs
    await dexiedb.transaction("rw", tables, async () => {
      for (let i = 0; i < cNs.length; i++) {
        await tables[i].bulkDelete(groupedByCn[cNs[i]]);
      }
    });

    return {
      data: {
        obj: { _id: ids[0] },
        objs: ids.map((item) => ({ _id: item })),
      },
    };
  } catch (error) {
    log.error(error);
    return {
      error: { msg: `Calling post() of dexieApis error: ${error}` },
    };
  }
}

/**
 * Marks docs as 'deleted' by setting their deleted field to a timestamp (a "virtual delete").
 * - They remain in Dexie but are effectively flagged as removed. 
 * - This also marks them as 'delete' in changedDocs so the server can see that they're removed.
 *
 * @param {string} [objId] - A single ID like "posts_123".
 * @param {string[]} [objIds] - Multiple IDs. 
 * @param {boolean} [awaitSyncDB=true] - If true, wait for sync after marking them. 
 * @returns {Object|undefined} On success, { data: { obj, objs }}; if invalid input, undefined.
 */
async function delVirtual({ objId, objIds, awaitSyncDB = true }) {
  // Must provide exactly one
  if (
    (objId !== undefined && objIds !== undefined) ||
    (objId === undefined && objIds === undefined)
  ) {
    console.error("You must provide either obj_id or obj_ids");
    return;
  }

  let ids = objId || objIds;
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  // Group them by collection
  const cNandId = ids.map((id) => ({ cn: getCn(id), id }));
  const groupedByCn = cNandId.reduce((acc, item) => {
    if (!acc[item.cn]) {
      acc[item.cn] = [];
    }
    acc[item.cn].push(item.id);
    return acc;
  }, {});

  try {
    await ensureDBOpen();
    const nowStr = getNowIsoStr();

    // For each collection, retrieve docs, set deleted=nowStr, modified=nowStr, re-insert with bulkPut
    const cNs = Object.keys(groupedByCn);
    const tables = cNs.map((cn) => dexiedb.table(cn));

    await dexiedb.transaction("rw", tables, async () => {
      for (let i = 0; i < cNs.length; i++) {
        const docs = await tables[i].bulkGet(groupedByCn[cNs[i]]);
        const updatedDocs = docs.map((doc) => {
          doc.deleted = nowStr;
          doc.modified = nowStr;
          return doc;
        });
        await tables[i].bulkPut(updatedDocs);
      }
    });

    // Then mark them as "delete" in changedDocs
    const globalsTable = dexiedb.table("globals");
    dexiedb.transaction("rw", globalsTable, async () => {
      await globalsTable.update("globals_db", { modified: nowStr });
      await addChangedDocInfoToGlobal(
        ids.map((item) => ({
          id: item,
          action: DOC_DELETE,
        }))
      );
    });

    // Possibly sync
    if (awaitSyncDB) {
      await syncDB();
    } else {
      syncDB();
    }

    return {
      data: {
        obj: { _id: ids[0] },
        objs: ids.map((item) => ({ _id: item })),
      },
    };
  } catch (error) {
    log.error(error);
    return {
      error: { msg: `Calling delVirtual() of dexieApis error: ${error}` },
    };
  }
}

/**
 * Reset the database.
 * @returns {Promise<void>}
 */
export async function resetDexieDB() {
  if (!dexiedb.isOpen()) {
    await dexiedb.open();
  }

  const globalsTable = dexiedb.table("globals");
  
  // reset the sync time stamp and other necessary fields
  await globalsTable.update(ID_GLOBALS_DB, {
    synced: DEFAULT_CREATED_CS,
    modified: DEFAULT_CREATED_CS,
    changedDocs: {},
    syncingDocs: {},
    dbVersion: "",  // reset the database version
  });

  // reset the field permissions
  await globalsTable.update("globals_fieldPermissions", {
    notViewable: {},
    editable: {},
    deletable: [],
  });

  // clear all tables (except globals)
  const tables = dexiedb.tables.filter(table => table.name !== "globals");
  await Promise.all(tables.map(table => table.clear()));
}

export {
  post,
  getByIds,
  getByCollectionName,
  put,
  putUser,
  patch,
  bulkPatch,
  delReal,
  delVirtual,
  sortObjs,
  paging,
  addChangedDocInfoToGlobal,
};
