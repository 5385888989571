/**
 * Constants used by filtering components.
 *
 * NOTE:  The idea of object "types" doesn't map exactly to object
 * collection names or any other specific object property.
 * I suspect many other values from the filter UI will not either.
 * So, the consumer of the the filterInfo object will have to map
 * the values in a filterInfo object to a database query that
 * corresponds to the UI's idea of the filter settings.
 */

const TYPE_USER = "user";
const TYPE_ACTIVITY = "activity";
const TYPE_FOOD = "food";
const TYPE_ACCOMMODATION = "accommodation";

const FILTER_TYPE = "types";
const FILTER_LABEL = "labels";
const FILTER_PRICE_RANGE = "priceRange";
const FILTER_DIFFICULTY = "difficultyRange";
const FILTER_STATUS = "status";
const FILTER_SEARCH = "search";
const FILTER_CREATOR = "creator";
const FILTER_SELECTED = "selected";
const FILTER_TOUSERID = "toUserId";
const FILTER_RESPONSE = "response";
const FILTER_GROUPS = "groups";
const FILTER_OWNER = "owner";
const FILTER_LOCATION = "location";
const FILTER_SEASONAL = "seasonal";
const FILTER_CATEGORIES = "categories";

const FILTER_VALUE_RESPONSE_PENDING = "pending";
const FILTER_VALUE_RESPONSE_REJECTED = "rejected";
const FILTER_VALUE_RESPONSE_IGNORE = "ignored";

const FILTER_VALUE_SHOW = 1;
const FILTER_VALUE_HIDE = -1;
const FILTER_VALUE_NONE = 0;

const SORT_ASC = 1;
const SORT_DESC = -1;
const SORT_NONE = 0;

const SORT_SELECTED = "selected";
const SORT_SELECTED_INFO = {
  displayLabel: "Selected",
  value: SORT_NONE,
};

const DIFFICULTY_LEVELS = {
  [1]: "Easy",
  [2]: "Moderate",
  [3]: "Difficult",
  [4]: "Advanced",
  [5]: "Extreme",
};

const FilterConstants = {
  TYPE_USER,
  TYPE_ACTIVITY,
  TYPE_FOOD,
  TYPE_ACCOMMODATION,

  FILTER_TYPE,
  FILTER_LABEL,
  FILTER_PRICE_RANGE,
  FILTER_DIFFICULTY,
  FILTER_STATUS,
  FILTER_SEARCH,
  FILTER_CREATOR,
  FILTER_SELECTED,
  FILTER_TOUSERID,
  FILTER_GROUPS,
  FILTER_OWNER,
  FILTER_LOCATION,
  FILTER_SEASONAL,
  FILTER_CATEGORIES,

  FILTER_RESPONSE,
  FILTER_VALUE_RESPONSE_PENDING,
  FILTER_VALUE_RESPONSE_REJECTED,
  FILTER_VALUE_RESPONSE_IGNORE,

  FILTER_VALUE_SHOW,
  FILTER_VALUE_HIDE,
  FILTER_VALUE_NONE,

  SORT_ASC,
  SORT_DESC,
  SORT_NONE,
  SORT_SELECTED,
  SORT_SELECTED_INFO,

  DIFFICULTY_LEVELS,

  // Default filterInfo used.
  // We might need to have a few versions of this.
  // Different versions for filtering User objects vs.
  // filtering activities vs. filtering a heterogenious list
  // of objects.
  DEFAULT_FILTER_INFO: {
    types: {
      ["user"]: {
        selected: false,
        filters: {
          /*More specific sub-filters go in this object.*/
        },
      },
      ["activity"]: {
        selected: false,
        filters: {},
      },
      ["food"]: {
        selected: false,
        filters: {},
      },
      ["accommodation"]: {
        selected: false,
        filters: {},
      },
    },
    labels: [],
    priceMin: undefined,
    priceMax: undefined,
    difficultyMin: undefined,
    difficultyMax: undefined,

    // Need to come up with props for date/time values that make
    // sense for different kinds of objects.  For example:
    //
    //    Music Concert has a date and start time.  Possibly and end time.
    //    Boat Taxi Service has a start and end time each day.
    //    A Hike or Bike ride might have a concept of "Daytime".
    //    Glow worm cave might have a concept of "Nighttime".
  },
};

export default FilterConstants;
