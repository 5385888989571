// Maximum JavaScript Date is defined in ECMA-262 as ±100,000,000
// (one hundred million) days relative to January 1, 1970 UTC
// const EXPIRES_NEVER = (new Date(8640000000000000)).toISOString();
// But, the above string makes RxDB unhappy because it does not think
// it is an ISOString.  Year below is under 10,000, so it is happy.
//export const EXPIRES_NEVER = (new Date(250000000000000)).toISOString();
//export const EXPIRES_NEVER = (new Date(250000000000000)).getTime();

//export const DEFAULT_CREATED = (new Date(0)).getTime();
//export const DEFAULT_CREATED = new Date(0).toISOString();
//export const DEFAULT_CREATED = "2000-01-01T10:39:48.021+13:00";
export const DEFAULT_CREATED_CS = "1970-01-01T00:00:00.000Z";
export const DEFAULT_CREATED_SS = "1970-01-01T00:00:00.001Z";

//export const PRIVATE_ID = "users_privateUserId";
//export const DEFAULT_USER_ID = "users_defaultUserId";
export const FOSTER_USER_ID = "users_auth0|5f597d0925bd5200728f880d";
export const SUPPORT_USER_ID = "users_auth0|5f5976b63e11cd006ae6597c";
export const ADMIN_USER_ID = "users_auth0|5f5990c2ac40ef00722d5922";
export const ROOT_USER_ID = "users_auth0|5f5980be3e11cd006ae65a73";

export const FIREBASE_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/oroborionfree01.appspot.com/o/";

export const MISSING_IMAGE_URL = "/images/placeholders/broken-image.png";
export const IMAGE_NOT_SET_ICON = "image.svg";
export const IMAGE_NOT_SET_IMAGE = "/icons/image.svg";
export const IMAGE_PLACEHOLDER_IMAGE = "/images/placeholders/loginToView.png";

export const IMAGE_STATUS = {
  LOGIN_REQUIRED: "login_required",
  NOT_APPROVED: "not_approved",
  NOT_SET: "not_set",
};

export const DEFAULT_USER_ICON = "/icons/user.svg";
export const DEFAULT_WAY_IMAGE =
  "/icons/reshot-icon-trail-signs-Q7HL849V5B.svg";
export const DEFAULT_ACT_IMAGE = "/icons/treasure-map1.svg";
export const DEFAULT_ITN_IMAGE = "/icons/itinerary.svg";
export const DEFAULT_POST_IMAGE = "/icons/post.svg";

export const BLUR_USER_BASE64_URL =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNDA2LjUgMzk5LjZDMzg3LjQgMzUyLjkgMzQxLjUgMzIwIDI4OCAzMjBIMjI0Yy01My41IDAtOTkuNCAzMi45LTExOC41IDc5LjZDNjkuOSAzNjIuMiA0OCAzMTEuNyA0OCAyNTZDNDggMTQxLjEgMTQxLjEgNDggMjU2IDQ4czIwOCA5My4xIDIwOCAyMDhjMCA1NS43LTIxLjkgMTA2LjItNTcuNSAxNDMuNnptLTQwLjEgMzIuN0MzMzQuNCA0NTIuNCAyOTYuNiA0NjQgMjU2IDQ2NHMtNzguNC0xMS42LTExMC41LTMxLjdjNy4zLTM2LjcgMzkuNy02NC4zIDc4LjUtNjQuM2g2NGMzOC44IDAgNzEuMiAyNy42IDc4LjUgNjQuM3pNMjU2IDUxMkEyNTYgMjU2IDAgMSAwIDI1NiAwYTI1NiAyNTYgMCAxIDAgMCA1MTJ6bTAtMjcyYTQwIDQwIDAgMSAxIDAtODAgNDAgNDAgMCAxIDEgMCA4MHptLTg4LTQwYTg4IDg4IDAgMSAwIDE3NiAwIDg4IDg4IDAgMSAwIC0xNzYgMHoiLz48L3N2Zz4=";

export const MAX_IMAGE_SIZE_IN_BYTES = 100000;

// "widthMulti" allows the multiimages have varied aspect ratios
// depending on the child images.
// "both" makes the multiImage be square, but still allows child
// images to have varied aspect ratios.
export const CHILD_IMAGE_FIT_PARENT_DIMENSIONS = "both";

export const VIEW_TYPE = {
  CARD: "card",
  MAP: "map",
  LIST: "list",
  ORDERED_LIST: "orderedList",
  LIST_MIN_MAX: "minMax",
  SELECT_ORDER: "selectOrder",
};

export const MODE_VIEW = "view";
export const MODE_EDIT = "edit";
export const MODE_MAP = "map";
export const MODE_SELECT = "select";

export const SCROLL_Y = "scrollingPanelScrollYPosition";

export const DEFAULT_LOCATION = {
  lat: -41.2865,
  lng: 174.7762,
};

// NOTE: These sorts of strings should be in a language bundle.
export const DISPLAY_STR_NOT_SET = "Not set";
export const SHOULD_NOT_HAPPEN_ERROR = "Error";

export const DEFAULT_ZOOM_LEVEL = 6;
export const DEFAULT_ZOOM_LEVEL_FOR_PICKER = 14;
export const MAX_ZOOM_LEVEL = 15;
export const MAP_BOUNDS_PADDING_IN_PX = 50;
export const MIN_ZOOM_FOR_LOAD_MORE = 14; //street level

// URL search parameter names.
export const URLSearchParams = {
  COL_NM: "cN",
  COL_NM_ARRAY: "cNArray",
  VIEW_TYPE: "viewType",
  OBJ_ID: "objId",
  PROP_NAME: "propName",
  PROP_CN: "propCn",
  PROP_FS_KEY: "propFsKey",
  MODE: "mode", // MODE_VIEW, MODE_EDIT
  TITLE: "title",
  SC_MODE: "scMode",
  UI_SPEC: "uiSpec",
  FRIENDS: "friends",
  SCROLL_TO_ID: "scrollToId",
  MAX_COUNT: "maxCount",
  TO_USER_ID: "toUserId",
};

// These should be lower case, or even just single letters like "n", "a", "f",
// but that will break the existing DB.
export const STATUS_NEW = "New";
export const STATUS_APPROVED = "Approved";
export const STATUS_FLAGGED = "Flagged";

// These will eventually be localized to the user's language.
export const STATUS_LABELS = {
  [STATUS_NEW]: "New",
  [STATUS_APPROVED]: "Approved",
  [STATUS_FLAGGED]: "Flagged",
};

export const DIFFICULTY_LEVELS = [
  {
    value: "level1",
    label: "Easy",
    description:
      "Accessible to everyone, including children and elderly (look for a “disabled” identifier, notifying if handicap accessible).",
  },
  {
    value: "level2",
    label: "Moderate",
    description:
      "Slightly more difficult than easy, as it does require a level of stamina to complete. (5km walking without much vertical change. Cycling less than 15km without hills.)",
  },
  {
    value: "level3",
    label: "Difficult",
    description:
      "Provides a high degree of challenge, and will require a level of athleticism to complete (As much as 5 hours of walking with as much as 500 meters of ascent.)",
  },
  {
    value: "level4",
    label: "Advanced",
    description:
      "Very strenuous activities, though still achievable by a highly athletic lay person (As much as 8 hours of walking, and/or 20km of walking with ascents of 1000 meters.)",
  },
  {
    value: "level5",
    label: "Extreme",
    description:
      "Requires extensive training or expertise in a field, both for stamina and technical proficiency. (9 or more hours of walking with over 1000 meter ascents, over 25km walking distance, possible river crossings, special equipment might be needed, extreme weather might be a consideration.)",
  },
];

export const REACTION_TYPES = {
  like: "Like",
  love: "Love",
  haha: "Haha",
  wow: "Wow",
  angry: "Angry",
};

export const REACTION_LIKE = "Like";
export const REACTION_LOVE = "Love";
export const REACTION_HAHA = "Haha";
export const REACTION_WOW = "Wow";
export const REACTION_ANGRY = "Angry";

// Approximate Card widths in pixels.
const CARD_WIDTH_VALUE_XXSMALL = 100;
const CARD_WIDTH_VALUE_XSMALL = 150;
const CARD_WIDTH_VALUE_SMALL = 200;
const CARD_WIDTH_VALUE_MEDIUM = 250;
const CARD_WIDTH_VALUE_LARGE = 300;
const CARD_WIDTH_VALUE_XLARGE = 400;
const CARD_WIDTH_VALUE_XXLARGE = 500;

// Approximate Card widths in pixels.
export const CARD_WIDTHS = [
  { value: CARD_WIDTH_VALUE_XXSMALL, label: "XX-Small" },
  { value: CARD_WIDTH_VALUE_XSMALL, label: "X-Small" },
  { value: CARD_WIDTH_VALUE_SMALL, label: "Small" },
  { value: CARD_WIDTH_VALUE_MEDIUM, label: "Medium" },
  { value: CARD_WIDTH_VALUE_LARGE, label: "Large" },
  { value: CARD_WIDTH_VALUE_XLARGE, label: "X-Large" },
  { value: CARD_WIDTH_VALUE_XXLARGE, label: "XX-Large" },
];

const FONT_SIZE_VALUE_XXSMALL = 10;
const FONT_SIZE_VALUE_XSMALL = 12;
const FONT_SIZE_VALUE_SMALL = 14;
const FONT_SIZE_VALUE_MEDIUM = 16;
const FONT_SIZE_VALUE_LARGE = 18;
const FONT_SIZE_VALUE_XLARGE = 20;
const FONT_SIZE_VALUE_XXLARGE = 24;
// TODO: Figure out where we are going to set this.
// html element?  body element?
// TODO: Figure out whether we want to use a px size
// or use CSS values like "smaller", "larger", and so on.
export const FONT_SIZES = [
  { value: FONT_SIZE_VALUE_XXSMALL, label: "XX-Small" },
  { value: FONT_SIZE_VALUE_XSMALL, label: "X-Small" },
  { value: FONT_SIZE_VALUE_SMALL, label: "Small" },
  { value: FONT_SIZE_VALUE_MEDIUM, label: "Medium" },
  { value: FONT_SIZE_VALUE_LARGE, label: "Large" },
  { value: FONT_SIZE_VALUE_XLARGE, label: "X-Large" },
  { value: FONT_SIZE_VALUE_XXLARGE, label: "XX-Large" },
];

export const DEFAULT_USER_SETTINGS = {
  cardWidthPx: CARD_WIDTH_VALUE_MEDIUM,
  fontSizePx: FONT_SIZE_VALUE_MEDIUM,
};

export const UI_SPEC_USER_SETTINGS = "userSettings";

export const MISSING_OBJ = {
  _id: "This value gets replaced",
  isMissingObj: true,
  displayName: "Missing/Deleted Object",
};

export const SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  REGULAR: "regular",
};

export const PAGE_SIZE = 40;
export const PAGE_SIZE_MAP = 100;
export const POSTS_PAGE_SIZE = 5;
export const VIEWPORT_BUFFER_RATIO = 0.5;

export const MAX_CHARS = {
  NAME: 30,
  DESCRIPTION: 500,
  LABEL: 30,
  URL: 2048,
  EMAIL: 320,
  LAT_LNG: 20,
  EMAIL_PREFIX: 64,
};

export const PROP_NAMES = {
  ACTS: "acts",
  ARRANGEMENT: "arrangement",
  AUTO_APPROVE: "autoApprove",
  CATEGORIES: "categories",
  CONTENT: "content",
  COVER_IMAGE_INFO: "coverImageInfo",
  CREATOR: "creator",
  DATE_RANGE: "dateRange",
  DESCRIPTION: "description",
  DIFFICULTY: "difficulty",
  DISPLAY_NAME: "displayName",
  EMAIL: "email",
  EMAIL_VERIFIED: "emailVerified",
  FAVORITES: "favorites",
  FLAGS: "flags",
  FRIENDS: "friends",
  FROM_USER_ID: "fromUserId",
  ID: "_id",
  IMAGE_INFOS: "imageInfos",
  IMAGE_INFOS_LAYOUT_NAME: "imageInfosLayoutName",
  IMAGE_URL: "imageUrl",
  LABEL_IDS: "labelIds",
  LINKS: "links",
  LOCATION_INFO: "locationInfo",
  MESSAGE: "message",
  NAVIGATION: "navigation",
  OWNER: "owner",
  PERMISSION: "permission",
  PHONE_NUMBER: "phoneNumber",
  PHOTOGRAPHER_NAME: "photographerName",
  PRICE_INFO: "priceInfo",
  RATING: "rating",
  RECOMMENDED_ACTS_AND_ITNS: "recommendedActsAndItns",
  RECOMMENDED_GUIDES: "recommendedGuides",
  REVIEW: "review",
  SEASONAL: "seasonal",
  STATUS: "status",
  THUMBNAIL_IMAGE_INFO: "thumbnailImageInfo",
  TO_USER_ID: "toUserId",
  USERS: "users",
  WAYS: "ways",
};

// TODO: Refactor how we decide whether to show the mobile version of the UI.
export const MAX_WIDTH_PX_PHONE = 640;
